<template>
  <div class="subnav">
    <div class="subnav-item flex" @click="handlerName(6)">
      <div>{{ $t("sign.desc41") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="handlerName(1)">
      <div>{{ $t("home.desc7") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="handlerName(2)">
      <div>{{ $t("home.desc8") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="handlerBlind('/invite')">
      <div>{{ $t("home.desc6") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="handlerBlind('/reward')">
      <div>{{ $t("blind.desc1") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="handlerName(3)">
      <div>{{ $t("home.desc9") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="handlerName(5)">
      <div>{{ $t("login.desc11") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="handlerName(7)">
      <div>{{ $t("sign.desc51") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" @click="$router.push('/brand')">
      <div>{{ $t("brand.desc83") }}</div>
      <img src="../public/images/righticon.png" alt="" class="iconimg1" />
      <img src="../public/images/rightiocn2.png" alt="" class="iconimg2" />
      <img src="../public/images/rightiocn3.png" alt="" class="iconimg3" />
    </div>
    <div class="subnav-item flex" v-if="transferShow" style="cursor: pointer;" @click="handlerName(8)">
      <div>{{ $t('transferAccount.desc20') }}</div>
    </div>
    <div class="subnav-item flex" @click="handlerName(4)">
      <div>{{ $t("home.desc10") }}</div>
    </div>
   
    <el-dialog
      title="提示"
      v-model="dialogBackset"
      width="30%"
      :append-to-body="true"
      class="modalinfo"
      :modal="false"
    >
      <div class="modalinfo-con">
        <div class="modalinfo-con-title" style="margin-bottom: 30px">
          {{ $t("home.desc44") }}
          <!-- <img src="../public/images/closemodal.png" alt="" @click="dialogBackset = false" /> -->
        </div>
        <div class="modalinfo-con-btn flex">
          <div class="flexcenter" @click="dialogBackset = false">
            {{ $t("home.desc45") }}
          </div>
          <div class="flexcenter" @click="handlerLogout">
            {{ $t("home.desc46") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "../store";
export default {
  inject: ['reload'],
  data() {
    return {
      bindGoogle: false,
      address: "",
      dialogBackset: false,
      transferShow:false,
    };
  },
  mounted() {
    let address = this.$store.state.address;
    this.address =
      address.substring(0, 4) +
      "...." +
      address.substring(address.length - 4, address.length);
    this.bindGoogle = this.$store.state.userData.hasBindGoogleAuth;
    this.transferShow = this.$store.state.transferShow;
  },
  methods: {
    handlerBlind(url) {
    
      this.$emit("handlerSwitch", 1);
       this.$router.push(url)
    },
    handlerName(i) {
      if (i == 1) {
        this.$emit("handlerSwitch", "Trade");
        return;
      }
      if (i == 2 && !this.bindGoogle) {
        this.$emit("handlerSwitch", "Google");
        return;
      }
      if (i == 2 && this.bindGoogle) {
        this.$emit("handlerSwitch", "unbindGoogle");
        return;
      }
      if (i == 3) {
        let info = this.$store.state.h5Url.find(
          (item) => item.tag == "HELP_CENTER"
        );

        if (info != undefined) {
          window.open(info.url);
        }
      }
      if (i == 4) {
        this.dialogBackset = true;
      }
      if (i == 5) {
        let info = this.$store.state.h5Url.find(
          (item) => item.tag == "CUSTOMER_SERVICE_LINK"
        );
        if (info != undefined) {
          window.open(info.url);
        }
      }
      if (i == 6) {
        this.$emit("handlerSwitch", "AccountInfo");
      }
      if (i == 7) {
        let info = this.$store.state.h5Url.find(
          (item) => item.tag == "ANNOUNCEMENT"
        );

        if (info != undefined) {
          window.open(info.url);
        }
      }
      if(i==8){
        if(this.$store.state.isHvUser){
          this.$emit("handlerSwitch", "TransferAccount");
        }else{
          this.$message.success(this.$t('transferAccount.desc21'));
        }
        return;
      }
    },
    handlerLogout() {
      this.$message.success(this.$t('home.desc47'))
      store.commit('SET_TOKEN', '')
      store.commit('SET_USERDATA', {})
      store.commit('SET_LOGIN', false)
      localStorage.removeItem('vuex');
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      this.dialogBackset = false;
      this.$emit('handlerSwitch', 1);
      this.$router.push('/home');
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
  },
};
</script>

<style lang="less" scoped>
.subnav {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .subnav-item {
    margin-top: 30px;
    cursor: pointer;

    &:hover {
      div {
        color: #c9fa5b;
      }

      .iconimg2 {
        display: block;
      }

      .iconimg1 {
        display: none;
      }
    }

    &:last-child {
      div {
        color: #eb4343;
      }
    }

    div {
      flex: 1;
      overflow: hidden;
      font-size: 16px;
      font-family: "Nebula";
      color: #ffffff;
      line-height: 18px;
      white-space: nowrap;
    }

    img {
      flex: 0 0 5px;
      margin-top: 4px;
      width: 5px;
      height: 9px;
    }

    .iconimg2 {
      display: none;
    }

    .iconimg3 {
      display: none;
    }
  }
}

.mobilenav {
  display: none;
}

@media (max-width: 1200px) {
  .subnav {
    width: 283px;
    margin: 0 auto;
    padding: 0 0 30px;
  }

  .mobilenav {
    display: flex;
  }
}

@media (max-width: 767px) {
}
</style>

import Cryptojs from "crypto-js";
import md5 from "js-md5";
import BigNumber from "bignumber.js";
export default {
  dateBJtoLocal(time) {
    // 当前时区与标准地区的差值(分钟)
    const offset = new Date().getTimezoneOffset();
    // 北京时间戳
    const bjDate = new Date(time).getTime();
    // 当地时间戳
    const ddDate = (-offset / 60 - 8) * 60 * 60 * 1000 + bjDate;
    const date = new Date(ddDate);
    return date;
  },
  gettime(time) {
    return new Date(time.getTime() + (parseInt(time.getTimezoneOffset() / 60) + 8) * 3600 * 1000);
  },
  getValueMultip(value, value2) {
    return new BigNumber(value).multipliedBy(value2).toFixed();
  },
  getValueMinus(value, value2) {
    // 减法
    let bg1 = new BigNumber(value);
    let bg2 = new BigNumber(value2);
    return bg1.minus(bg2).toFixed();
  },
  getValueAdd(value, value2) {
    //加法
    var bg1 = new BigNumber(value);
    var bg2 = new BigNumber(value2);
    return bg1.plus(bg2).toFixed();
  },
  formatDate(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + "-";
    var MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    var ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + " " + hh + mm + ss;
  },
  formatDate1(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + "-";
    var MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    var ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD;
  },
  formatDate2(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + "/";
    var MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "/";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    var ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD;
  },
  formatDate3(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + "/";
    var MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "/";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return MM + DD + " " + hh + mm;
  },
  formatDate4(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + "-";
    var MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    var ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return hh + mm + ss;
  },
  formatDate5(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + "-";
    var MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()+ ":";
    var ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return MM + DD + " " + hh + mm + ss;
  },
  mdpassword(data) {
    return md5(data);
  },
  toFixed(num, k) {
    return parseFloat(num).toFixed(k);
  },

  numToFixed(number, len = 2) {
    try {
      const strNumber = number.toString();
      const decimalIndex = strNumber.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPart = strNumber.slice(
          decimalIndex + 1,
          decimalIndex + len + 1
        );
        // .padEnd(len, "0");
        console.log({ decimalPart });
        const _num = strNumber.slice(0, decimalIndex + 1) + decimalPart;

        return _num;
      } else {
        return Number(number); // 如果整数部分没有小数点，则使用toFixed方法
      }
    } catch (error) {
      return number;
    }
  },
  getnum(value) {
    let str = Math.floor(value * 100) / 100;
    return str;
  },
  cutXiaoNum1(num) {
    if (num == undefined) {
      return;
    }
    // let str = num.substring(0, num.indexOf(".") + 5);
    // return str;

    let numStr = num.toString();
    const flag = numStr.includes(".");

    let newNum;
    if (flag) {
      let length = numStr.split(".")[1].length;
      if (length > 4) {
        newNum = numStr.substring(0, numStr.indexOf(".") + 5);
      } else {
        newNum = numStr;
      }
    } else {
      newNum = numStr;
    }
    return newNum;
  },
  cutXiaoNum2(num) {
    if (num == undefined) {
      return;
    }
    // let str = num.substring(0, num.indexOf(".") + 5);
    // return str;
    let numStr = num.toString();
    const flag = numStr.includes(".");

    let newNum;
    if (flag) {
      let length = numStr.split(".")[1].length;
      if (length > 4) {
        newNum = numStr.substring(0, numStr.indexOf(".") + 5);
      } else {
        newNum = parseFloat(numStr).toFixed(4);
      }
    } else {
      newNum = parseFloat(numStr).toFixed(4);
    }
    return newNum;
  },
  cutXiaoNum(num, len) {
    let numStr = num.toString();
    const flag = numStr.includes(".");

    let newNum;
    if (flag) {
      let length = numStr.split(".")[1].length;
      if (length > 8) {
        newNum = parseFloat(numStr).toFixed(8);
      } else {
        newNum = numStr;
      }
    } else {
      newNum = numStr;
    }
    return newNum;
  },
  //  加密方法
  aes(word) {
    const key = Cryptojs.enc.Utf8.parse("4063EF9C087ECCFCF0ACDD22C127F445");
    const iv = Cryptojs.enc.Utf8.parse("0123456789ABCDEF");
    let srcs = Cryptojs.enc.Utf8.parse(word);
    let encrypted = Cryptojs.AES.encrypt(srcs, key, {
      iv: iv,
      mode: Cryptojs.mode.CBC,
      padding: Cryptojs.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  //  解密方法
  //  data：要解密的字符串
  //  key：加密因子
  //  iv：偏移量
  ads(data) {
    const key = Cryptojs.enc.Utf8.parse("4063EF9C087ECCFCF0ACDD22C127F445"); // 加密因子
    const iv = Cryptojs.enc.Utf8.parse("0123456789ABCDEF"); // 偏移量
    let encryptedHexStr = Cryptojs.enc.Hex.parse(data);

    let str = Cryptojs.enc.Base64.stringify(encryptedHexStr);

    let decrypted = Cryptojs.AES.decrypt(str, key, {
      iv: iv,
      mode: Cryptojs.mode.CBC, // AES加密模式
      padding: Cryptojs.pad.Pkcs7, // 填充:  pkcs7 paddin
    });
    return decrypted.toString(Cryptojs.enc.Utf8).toString();
  },
  sign(obj) {
    let apiSecret = "test";
    if (process.env.NODE_ENV == "production") {
      apiSecret = "T39MJ3W94vv8qyg";
    }

    var keys = [];
    for (let key in obj) {
      keys.push(key);
    }
    keys.sort();
    let strTemp = "";
    for (let k in keys) {
      let value = obj[keys[k]];
      if ("string" != typeof value) {
        value = JSON.stringify(value);
      }
      strTemp += keys[k] + "=" + value + "&";
    }
    strTemp = strTemp.replace(/&$/, ""); //去掉最后的逗号
    return Cryptojs.HmacSHA256(strTemp, apiSecret).toString();
  },
  GetRandomNum(min, max) {
    var range = max - min;
    var rand = Math.random();
    return min + Math.round(rand * range);
  },
};

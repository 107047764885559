export default {
  login: {
    sendverify: "verify/code/send", //验证码
    register: "user/register", //注册
    sign: "user/login", //登录
    forget: "user/password/login/forget", //忘记密码
    account: "user/account/forget", //忘记账号
    hvsign: "user/hc/login", //hv
    hvbind: "user/info/email/bind", //hv绑定
    bindSal: "user/info/account/email/bind",
    bindhcuser:'user/info/hc/bind', //hc用户绑定
    showHvLogin:'index/hc/countdown',
    passwordset: "api/user/password/login/set", //设置登录密码
  },
  home: {
    check: "user/wallet/auth/check",
    userAuth: "/user/wallet/auth",
    chainList: "/chain/list",
    getKey: "/sign/login/sign/key",
    h5Url: "url/config/list",
    notice: "api/news/detail", //首页公告
    tickets: "index/market/tickets", //首页常见币种行情接口
    lang: "language/list", //语言
    banner: "banner/list", //banner
    galactic: "planet/galactic/list", //星系
    downLoadImg: "planet/icon/downLoad", //下载图片
    article: "article/detail",
    newNotice:'index/top/notice',//最新公告
  },
  member: {
    info: "/user/info/personal",
    update: "user/info/account/bind", //更新信息
    profile: "user/info/profile", //个人信息
  },
  user: {
    info: "user/info/personal", //信息
    google: "user/google/random", //谷歌
    setgoogle: "user/google/set", //设置谷歌
    trade: "user/password/transaction/update", //交易密码
    unbindgoogle: "user/google/unbind", //解绑谷歌
    useraccountset: "api/user/account/set", //设置用户名
    useremailset: "api/user/email/set", //设置邮箱
  },
  planet: {
    myland: "star/land/myland", //我的土地列表
    list: "star/era/market", //土地市场
    buy: "star/land/buy", //购买
    eralist: "star/era/list", //时代接口
    series: "star/land/myland/plant", //我的土地列表系列
    transferto: "star/land/transfer/transferto", //转让
    replow: "star/land/replow/before", //重耕信息
    replowConfirm: "star/land/replow/confirm", //重耕提交
    info: "star/planet/detail", //星球详情
    record: "star/land/transfer/list", //转账记录
    config:'activity/pay/back/speed/config',//加速配置
  },
  pool: {
    overview: "star/pledge/pool/income/overview", //收益
    list: "star/pledge/pool/list", //质押池列表
    record: "star/pledge/pool/record/myrecord", //质押记录
    infooverview: "star/pledge/pool/income/overview2", //质押详情收益
    pledge: "star/pledge/pool/deposit", //质押
    deposit: "star/pledge/pool/deposit", //质押土地
    redeem: "star/pledge/pool/record/withdraw", //质押赎回土地
    ablepledge: "star/land/able/pledge", //可质押土地
    recReceive: "star/reward/rec/receive", //直推收益领取
    communityReceive: "star/reward/community/receive", //社区收益领取
    outputReceive: "star/pledge/pool/output/receive", //土地收益领取
    receive: "income/receive", //收益领取
    eralist: "star/era/my/simple/list", //时代列表
    eraInfo: "star/era/my/detail", //时代详情
    usereralist: "star/era/my/list", //时代列表
  },
  assets: {
    info: "wallet/asset/list",
    view: "wallet/asset",
    priceData: "wallet/asset/price/data",
    type: "wallet/record/type/list",
    record: "wallet/record",
    detail: "wallet/record/detail",
    withdraw_config: "wallet/withdraw/config",
    withdraw: "wallet/withdraw",
    exchange_config: "wallet/exchange/config",
    exchange: "wallet/exchange",
    rechargeAddress: "wallet/recharge/address", //充币地址
    coinList: "coin/list", //币种列表
    recruit: "node/config", //节点配置
    recruitBuy: "node/buy", //购买节点
    recruitRecord: "node/record", //购买记录
    overview: "wallet/asset/overview", //统计信息
    rechargeRecord: "wallet/recharge/record", //充值记录
    withdrawRecord: "wallet/withdraw/record", //提现记录
    withdrawCal: "wallet/withdraw/cal", //提币计算
    transfer:'wallet/asset/transfer',//划转
    transferConfigList:'wallet/asset/transfer/config/select',//划转列表
    transferRecord:'wallet/asset/transfer/record',//划转记录
    transferConfig:'wallet/asset/transfer/config/list',//划转配置
    thirdRecord:'planet/balance/bag/hc/transfer/records',//资金包转移记录
    transferList:'wallet/asset/transfer/config/back/list',//划转配置
    rechargeConfig:'activity/pay/back/activityConfig',//充值配置

  },
  myincome: {
    statistics: "user/land/transaction/statistics", //用户土地交易统计信息接口
    incomeTodo: "user/land/income/todo", //获取待领取收益汇总信息
    recordList: "wallet/income/record/list", //收益领取记录列表与收益待领取记录列表接口
    withdraw: "land/earning/withdraw", //领取收益
    detail: "wallet/income/record/landEarning/detail", //领取收益-土地收益详情
    teamEarningDetail: "wallet/income/record/teamEarning/detail", //领取收益-代数奖励详情
  },
  star: {
    getStarList: "/star/planet/index/list",
    getAgeList: "/star/era/list",
    getDetails: "/star/planet/detail",
    list: "planet/index/list",
    details: "planet/rename/view",
    type: "planet/type/list", //
    galactic: "galactic/list", //星系
  },
  invite: {
    baseData: "statis/base/data", // 基础数据
    income: "statis/income", // 收益数据
    mypledge: "statis/mypledge", // 我的质押数据
    bounsPool: "statis/bouns/pool", // 资金池
    infoParent: "user/info/parent", // 上级信息接口
    inviteCode: "invite/code", // 获取邀请码接口
    directList: "team/direct/list", // 直邀成员列表
    info: "invite/user/info", //信息
    team: "team/info", //团队信息
    teamlist: "team/user/list", //团队列表
    planet: "invite/asset/info", //星球情况
    activity:'activity/home',//活动
  },
  swap: {
    config: "wallet/exchange/config", //获取闪兑配置接口
    record: "wallet/exchange/record", //闪兑记录接口
    exchange: "wallet/exchange", //闪兑接口
    coinList: "wallet/exchange/coin/list", //闪兑币种列表接口
    configList: "wallet/exchange/config/list", //获取闪兑配置列表接口
    coinmaskret: "coin/list", //币种列表
  },
  point: {
    info: "node/config", //配置
    buy: "node/buy",
  },
  node: {
    list: "planet/list", //列表
    info: "planet/rename/view", //详情
    random: "planet/rename/random", //随机
    pay: "planet/buy", //购买
    upload: "upload/img", //上传
    confirm: "planet/edit/confirm", //编辑信息
    modify: "planet/edit/view", //星球信息
    planet: "planet/info", //星球详情
  },
  manage: {
    list: "planet/own/list", //列表
    info: "planet/own/statistics", //统计
    message:'planet/trans/message/list',//赠送消息
    fee:'planet/trans/getTransAmount',//手续费
    transfer:'planet/trans/transfer',//转移
    isaccount:'planet/trans/checkUserByAccount',//判断UID是否存在
    read:'planet/trans/updateMessage',//已读
    record:'planet/trans/record',//转移记录
    hasopen:'planet/trans/planetTransConfig',//转移开关
  },
  market: {
    list: "land/list", //土地列表
    info: "land/info", //土地详情
    paycal: "land/pay/cal", //支付计算
    buy: "land/buy", //购买
    planetProfit: "user/land/transaction/statistics", //土地收益统计
    profitlist: "user/land/income/todo", //土地待领取收益统计
    landLevel: "land/level/list", //土地等级
    landlist: "user/land/list", //土地列表
    landStatus: "land/status/list", //土地状态
    disinvest: "land/disinvest/info", //撤资信息
    disinvestBtn: "land/disinvest", //撤资
    landAddInfo: "land/add/info", //追加投资信息
    landAdd: "land/add", //追加
    detail: "user/land/detail", //土地详情
    disinvestInfo: "land/disinvest/detail", //撤资详情
    withdraw: "land/earning/withdraw", //领取收益
  },
  assetbag: {
    bagList: "/planet/balance/bag/list",
    buyBagView: "planet/balance/bag/buy/view",
    buyBag: "planet/balance/bag/buy",
    ownBagList: "planet/balance/bag/own/list",
    backfillBagView: "planet/balance/bag/backfill/view",
    backfillBagCal: "planet/balance/bag/backfill/cal",
    backfillBag: "planet/balance/bag/backfill",
    bagDetail: "planet/balance/bag/detail",
    statistics: "planet/balance/bag/statistics/info",
    backfillRecords: "planet/balance/bag/backfill/records",
    releaseRecords: "planet/balance/bag/release/records",
    withdraw: "planet/balance/bag/release/withdraw",
  },
  quotes:{
    list:'wallet/exchange/exchangePriceList',//行情列表
    echarts:'wallet/exchange/exchangePriceChart',//行情折线图
    configInfo:'wallet/exchange/config',//闪兑配置详情
    configList:'wallet/exchange/config/list',//闪兑列表
    exchange:'wallet/exchange',//闪兑
    record:'wallet/exchange/record',//兑换记录
  },
  brand:{
    list:'weekly/list',//列表
    info:'weekly/newList',//详情
    type:'weekly/typeFileList',//类型
  },
  blind:{
    config:'blind/config',//盲盒配置
    check:'blind/checkDrawCondition',//是否有抽奖
    draw:'blind/draw',//抽奖
    record:'blind/blindBoxRecord',//记录
    allrecord:'blind/blindSystemBoxRecord',//
    blindBoxTotal:'blind/blindBoxTotal',//统计
  },
  reward:{
    blind:'blind/activity/list/blind',//盲盒配置
    planetList:'planet/activity/landPledgeWeeklyStatistics',//周榜统计
    marketConfig:'planet/activity/config',//周榜配置
    land:'activity/pay/back/land',//复投配置
    market:'planet/activity/planetLandActivityConfig',//星球
    marketOut:'planet/activity/planetLandSellOutStatistics',
    reinvestRecord:'activity/pay/back/addActivity',
    reinvestTotal:'activity/pay/back/add/now/record',//复投统计
    carve:'activity/pay/back/add/record',//瓜分记录
    prize:'activity/pay/back/amount/record',//奖励记录
    record:'activity/pay/back/add/record/user',
    initreinvest:'land/initialize/data',//初始化复投数据
    planetConfig:'activity/buy/planet/config',//土地活动规则
  },
  daot: {
    config:'dato/config',//daot认购配置
    buy:'dato/buy',//daot认购
    updateAddress:'dato/update/address',//daot修改钱包地址
    choice:'dato/choice',//选择是否认购dato
    state:'dato/state',//查询认购dato状态

    ReleaseList:'dato/getMyDaotInfo',
    oneKeyTake:'dato/oneKeyTake'
  },
  vote:{
    config:'vote/config',
    statistics:'vote/count',//投票统计
    option:'vote/option',
    submit:'vote',
    record:'vote/record'
  }
};

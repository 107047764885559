<template>
  <div class="header">

    <div class="header-con flex">
      <img src="../public/images/new/tabicon.png" alt="" class="menu-tab" @click="leftdrawer = true" />
      <img src="../public/images/logo.png" alt="" class="logo" @click="$router.push('/home')" />

      <div class="header-nav" v-if="pathName == '/login' ||
        pathName == '/register' ||
        pathName == '/forgot' ||
        pathName == '/forgotname' ||
        pathName == '/bindname' ||
        pathName == '/bindhv' ||
        pathName == '/error'
        "></div>
      <div class="header-nav flex" :class="{ minRight: lang != 'ZH' }" v-else>
        <div :class="{ textfont: lang == 'en_US' }" :style="{ color: pathName == item.path ? '#C9FA5B' : '#D0D0D0' }"
          v-for="item in list" :key="item.id" @click="handlerPath(item.path)">
          <!-- {{ item.name }} -->
          <p>{{ item.name }} <span v-if="isTips && item.path == '/manage'"></span></p>
        </div>
      </div>
      <div class="header-login flex">
        <div class="login-con flex" v-if="!isLogin">
          <div @click="$router.push('/login')">{{ $t("sign.desc1") }}</div>
          <div @click="$router.push('/register')">{{ $t("sign.desc2") }}</div>
        </div>
        <div class="login-name flexcenter" v-else @click="handlerdrawer">
          <img src="../public/images/new/avatar.svg" alt="" />
          {{ dealAddress(userinfo.account) }}
        </div>

        <div class="login-lang">
          <div class="mobilelang flex" @click="dialogBackset = true">
            <img src="../public/images/langicon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-drawer title="" v-model="drawer" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="true"
      :direction="direction" destroy-on-close :append-to-body="true">
      <elDraw @handlerClose="handlerClose" :type="type" v-if="drawer" />
    </el-drawer>
    <el-drawer title="" v-model="leftdrawer" :show-close="false" :close-on-press-escape="false"
      :close-on-click-modal="true" direction="ltr" destroy-on-close :append-to-body="true">
      <div class="left-nav">
        <div class="nav-logo">
          <img src="../public/images/logo.png" alt="" class="logo" />
        </div>
        <div class="nav-list">
          <div :style="{ color: pathName == item.path ? '#C9FA5B' : '#D0D0D0' }" v-for="item in list" :key="item.id"
            @click="handlerPath(item.path)">
            {{ item.name }}

          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 语言 -->

    <div class="modal-success" v-if="dialogBackset">
      <div class="lang-info">
        <div class="lang-title">
          {{ $t("login.desc13") }}
          <img src="../public/images/closemodal.png" alt="" @click="dialogBackset = false" />
        </div>
        <div class="lang-item flex" v-for="item in langList" :key="item.id" @click="handleCommand(item.code)">
          <div>{{ item.name }}</div>
          <img :src="item.code == lang
            ? require('../public/images/new/checkimg.png')
            : require('../public/images/new/checkimg1.png')
            " alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elDraw from "./rightNav.vue";
import { mapState } from "vuex";
import bus from "@/utils/bus";
export default {
  props: ['isTips'],
  components: {
    elDraw,
  },
  data() {
    return {
      leftdrawer: false,
      dialogBackset: false,
      // name: 'headerNav',
      drawer: false,
      direction: "rtl",
      lang: "",
      // pathName: '/home',
      list: [
        // {
        //     name: this.$t('home.desc'),
        //     path: '/home'
        // },
        // {
        //     name: this.$t('home.desc40'),
        //     path: '/point'
        // },
        {
          name: this.$t("new.desc1"),
          path: "/node",
        },
        {
          name: this.$t("home.desc63"),
          path: "/market",
        },
        {
          name: this.$t("new.desc3"),
          path: "/manage",
        },

        {
          name: this.$t("home.desc1"),
          path: "/planet",
        },
        {
          name: this.$t("new.desc5"),
          path: "/quotes",
        },
        {
          name: this.$t("home.desc2"),
          path: "/asset",
        },
        // {
        //   name: this.$t("home.desc6"),
        //   path: "/invite",
        // },
        {
          name: this.$t("lockrelease.desc20"),
          path: "/lockRelease",
        },
        {
          name:this.$t('vote.desc41'),
          path:'/vote'
        }
      ],
      langList: [],
      userinfo: {},
      type: ""
    };
  },
  computed: {
    ...mapState(["address", "isLogin", "pathName"]),
  },

  mounted() {
    this.lang = localStorage.getItem("language");
    bus.on("hadleropen", (e) => {
        this.drawer = true;
        this.type = e;
    });
    if (localStorage.getItem("token") != null) {

      this.userinfo = this.$store.state.userData;
      
      bus.on('onLogin', (e) => {
        this.userinfo = this.$store.state.userData;
      })
    } else {
      this.address = "";
    }
    if (localStorage.getItem('lang') == null) {
      this.$post(this.URL.home.lang, {}).then((res) => {
        if (res.code == 0) {
          this.langList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    } else {
      this.langList = JSON.parse(localStorage.getItem('lang'))
    }
  },
  methods: {
    handlerPath(url) {
      if (localStorage.getItem("token") == null) {
        if (url == "/home" || url == "/node") {
          this.leftdrawer = false;
          this.$router.push(url);
          return;
        }
        this.$router.push("/login");
        return;
      }
      this.leftdrawer = false;
      this.$router.push(url);
    },
    dealAddress(str) {
      if (str && str.length > 10) {
        return (
          str.substring(0, 4) +
          "...." +
          str.substring(str.length - 4, str.length)
        );
      } else {
        return str;
      }
    },
    handleCommand(val) {
      localStorage.setItem("language", val);
      this.dialogBackset = false;
      setTimeout(() => {
        location.reload();
      }, 600);
    },
    handlerClose() {
      this.drawer = false;
    },
    handlerSwitch(val) {
      this.name = val;
    },
    handlerdrawer() {
      this.drawer = true;
      this.type = 0;
      this.name = "headerNav";
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 96px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  z-index: 999;

  .header-con {
    width: 1240px;
    margin: 0 auto;
  }

  .menu-tab {
    display: none;
    cursor: pointer;
  }

  .logo {
    width: 198px;
    height: 31px;
    margin: 32px 0 0 0;
    cursor: pointer;
  }

  .header-nav {
    flex: 1;
    justify-content: space-between;

    div {
      font-size: 18px;
      line-height: 96px;
      color: #d0d0d0;
      cursor: pointer;
      text-align: center;
      font-weight: 400;

      &:first-child {
        margin-left: 28px;
      }

      &:hover {
        color: #c9fa5b !important;
      }

      p {
        position: relative;
        display: flex;

        span {
          width: 8px;
          height: 8px;
          margin-top: 33px;
          background: red;
          border-radius: 50%;
        }
      }
    }
  }

  .header-login {
    flex: 0 0 210px;
    width: 210px;
    justify-content: flex-end;

    .login-con {
      flex: 0 0 184px;
      padding: 33px 0;

      div {
        flex: 0 0 92px;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        font-size: 13px;
        color: #d0d0d0;
        text-align: center;

        &:last-child {
          background: url("../public/images/new/loginbtn.png") center no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .login-name {
      position: relative;
      // flex: 0 0 186px;
      height: 34px;
      padding-right: 20px;
      margin-top: 31px;
      // background: url('../public/images/new/loginbg1.png') center no-repeat;
      // background-size: 100% 100%;
      font-size: 13px;
      color: #d0d0d0;
      cursor: pointer;
      font-family: "Nebula";
      line-height: 34px;

      img {
        width: 20px;
        height: 23px;
        margin: 0 6px 0 0;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 8px;
        height: 6px;
        background: url("../public/images/new/downicon2.png") center no-repeat;
        background-size: 100% 100%;
        transform: translateY(-50%);
      }
    }

    .header-nav {
      flex: 1;
      justify-content: space-between;

      div {
        // margin-left: 35px;
        font-size: 18px;
        line-height: 96px;
        color: #d0d0d0;
        cursor: pointer;
        text-align: center;
        font-weight: 400;

        &:first-child {
          margin-left: 28px;
        }

        &:hover {
          color: #c9fa5b !important;
        }
      }
    }

    .mobilelogin {
      display: none;
    }

    .login-lang {
      margin: 38px 0 0 19px;
      height: 17px;
      cursor: pointer;

      .el-dropdown-link {
        display: flex;
        font-size: 15px;
        color: #d0d0d0;

        img {
          width: 8px;
          height: 5.5px;
          margin: 5px 0 0 4px;
        }
      }
    }

    .mobilelang {
      display: block;

      img {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }

  //弹窗
  ::v-deep .el-drawer {
    width: 500px !important;

    .el-drawer__header {
      display: none;
    }

    .el-drawer__body {
      padding: 0 80px;
    }
  }
}

.modal-success {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}

.lang-info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 240px;
  padding: 20px 0;
  background: #000;
  z-index: 56;
  transform: translate(-50%, -50%);
  border: 1px solid #3a3a3a;
  box-sizing: border-box;

  .lang-title {
    position: relative;
    padding: 0 20px 12px;
    font-size: 13px;
    color: #a8a8a8;

    img {
      position: absolute;
      top: 1px;
      right: 20px;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }

  .lang-item {
    width: 200px;
    margin: 0 auto;
    height: 44px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    div {
      flex: 1;
      font-size: 13px;
      color: #ffffff;
      line-height: 44px;
    }

    img {
      width: 16px;
      height: 16px;
      margin: 14px 0 0 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.left-nav {
  .nav-logo {
    margin: 30px 0 16px;
    text-align: center;

    img {
      height: 40px;
    }
  }

  .nav-list {
    div {
      padding: 16px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    height: 68px;
    background: #000;

    .header-con {
      width: inherit;
      padding: 0 15px;

      .header-nav {
        display: none;
      }

      .header-login {
        flex: 1;
        justify-content: flex-end;

        .login-con {
          padding: 17px 0 0;
        }

        .login-name {
          margin-top: 17px;
        }

        .pclogin {
          display: none;
        }

        .mobilelogin {
          display: flex;
        }

        .login-lang {
          margin: 25px 0 0 17px;
        }

        .pclang {
          display: none;
        }
      }

      .logo {
        width: auto;
        height: 22px;
        margin-top: 22px;
      }
    }

    .menu-tab {
      display: block;
      width: 30px;
      height: 30px;
      margin: 16px 16px 0 0;
    }

    ::v-deep .el-drawer {
      width: calc(100vw - 6px) !important;

      .el-drawer__body {
        padding: 0 31px;
      }
    }
  }

  .userinfo {
    .userinfo-top {
      padding: 21px 0 40px;

      .top-account {
        flex: 0 0 177px;
        height: 28px;
        background-size: 100% 100%;

        .img1 {
          margin-top: 10px;
        }

        .img2 {
          margin-top: 11px;
        }

        div {
          line-height: 28px;
        }
      }

      .login-lang {
        display: none;
      }

      .close-modal {
        margin-top: 4px;
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    display: none;
  }
}
</style>

const en_US = {
  common: {
    desc1: "Coming Soon",
    desc2: "Got it",
    desc3: "Confirm",
    desc4: "Reminder",
    desc5: "No more",
    desc6: "Deposit",
    desc7: 'Loading...',
    desc8:"500,We're sorry, but something went wrong. We've been notified about this issue and we'll take a look at it shortly."
  },
  sign: {
    desc1: "Log in",
    desc2: "Sign up",
    desc3: "Join the First-Ever Phenomenal Web3 Social-Fi Ecosystem",
    desc4:
      "Embark on an Astronomical Adventure! This is where your imagination grows beyond limit, and dreams become reality in VR mode.",
    desc5: "Log in to the planet",
    desc6: "Account",
    desc7: "Please enter your username or email",
    desc8: "Forgot username?",
    desc9: "Login password",
    desc10: "Please enter your password",
    desc11: "Remember password",
    desc12: "Forgot password?",
    desc13: "Confirm login",
    desc14: "Don't have an account?",
    desc15: "Register now",
    desc16: "HyperCommunity one-click login",
    desc17: "Username",
    desc18: "Max 10 characters",
    desc19:
      "Please be aware that once you have chosen your username, it cannot be changed. Hence, it is crucial to carefully select a username and ensure that you can remember it.",
    desc20: "Email address",
    desc21: "Please enter your email address",
    desc22: "Confirm login password",
    desc23: "Please enter your password again",
    desc24: "Email verification code",
    desc25: "Enter the verification code",
    desc26: "Send code",
    desc27: "Invitation code",
    desc28: "Please enter the invitation code",
    desc29:
      'By registering, you agree to the <span style="cursor: pointer;"> 《User Registration Agreement》</span>.',
    desc30: "Confirm Registration",
    desc31: "Already have an account？",
    desc32: "Log in now",
    desc33: "Please enter your username",
    desc34: "The passwords do not match",
    desc35: "Please enter a valid email format",
    desc36: "Switch language",
    desc37: "Verification code sent successfully",
    desc38: "Please agree to the agreement",
    desc39: "Registration successful",
    desc40: "Login successful",
    desc41: "Account Settings",
    desc42: "Step 1",
    desc43: "Please download 'Google Authenticator' from the app store",
    desc44: "Step 2",
    desc45: "Scan the QR code to bind with Google Authenticator",
    desc46: "Step 3",
    desc47: "Email",
    desc48: "My upline",
    desc49: "Account source",
    desc50: "Registration time",
    desc51: "Announcement",
    desc52: "Register an account",
    desc53: "Password: 8-20 characters, must contain uppercase and lowercase letters and numbers",
    desc54: "Retrieve login password",
    desc55: "Set password",
    desc56: "Confirm password",
    desc57: "Password changed successfully",
    desc58: "Retrieve username",
    desc59: "Email account",
    desc60: "Username has sent",
    desc61:
      "Your recommended username has been sent to you by the system via email. Please check your inbox.",
    desc62: "Okay",
    desc63: "Third-party login",
    desc64:
      "The {n1} user detected, please set up a unique username and email on the first login.",
    desc65: "Associated email address:",
    desc66: "Successfully set",
    desc67: "Daoversal",
    desc68:
      "Detected that you are a HyperVerse platform user. Please reset a unique email for your first login.",
    desc69: "Email address",
    desc70: "Your level:",
    desc71: 'Please set a new unique email',
    desc72: 'Forgot password',
    desc73: "Log in through wallet",
    desc74: "Log in through wallet",
    desc75: "Please enter the invitation code",
    desc76: "Please select the wallet to log in",
    desc77: "Invitation code",
    desc78: "Disconnect WalletConnect",
    desc79: "Please check the connected wallet and confirm the signature message",
  },
  quotes: {
    desc1: "Swap",
    desc2: "Token",
    desc3: "Price",
    desc4: "24H Change",
    desc5: "72H Change",
    desc6: "Operation",
    desc7: "Check",
    desc8: "{n1} Exchange {n2}",
    desc9: "Enter quantity",
    desc10: "Balance",
    desc11: "Account balance：",
    desc12: "Swap Record",
    desc13: "OrderID",
    desc14: "Time",
    desc15: "Pair",
    desc16: "Type",
    desc17: "Quantity",
    desc18: "Status",
    desc19: "Fees",
    desc20: "Loading...",
    desc21: "Min：{n1}",
    desc22: "Max：{n1}",
    desc23: "Swap Confimation",
    desc24: "Sell",
    desc25: "Price",
    desc26: "Quantity",
    desc27: "Receive",
    desc28: "Please enter quantity",
    desc29: "Swap Successful",
    desc30: "Records",
    desc31: "Details",
    desc32: "Market",
    desc33: "More",
    desc34:
      "Note: The price update time is 10 seconds. The final transaction price is subject to the latest price obtained when clicking OK.",
    desc35: "Buy",
    desc36: "Buy {n1}",
    desc37: "Exchange",
    desc38: "Receive",
  },
  land: {
    desc1:
      "Explore these uniquely named planets and start your interstellar journey! ",
    desc2:
      "Choose a planet name that resonates with your soul, and make your planet unique in the universe! ",
    desc3: "Search planet coordinates/holders/planet name",
    desc4: "Holder：",
    desc5: "Land Conditions",
    desc6: "Planet Level",
    desc7: "Planet Colonization",
    desc8: "Subscribe Now",
  },
  manage: {
    desc1:
      "Create your unique world in Daoversal! Make your planet a stellar standout with stunning photos.",
    desc2: "Planet Owned",
    desc3: "Activated",
    desc4: "Tax to Collect",
    desc5: "Top Staking",
    desc6: "Staking Record",
    desc7: "Developed",
    desc8: "Land Quantity: {n1}",
    desc9: "Value: {n1} DAOT",
    desc10: "Hinterland",
    desc11: "In Progress",
    desc12: "Your Star Empire",
    desc13: "Planet Name",
    desc14:
      "Create your unique world in Daoversal! Make your planet a stellar standout with stunning photos.",
    desc15: "Edit Planet",
    desc16: "{n1}",
    desc17: "Investment Quantity",
    desc18: "Land Tax",
    desc19: "Welcome To Planet Editor",
    desc20:
      "Please be advised that once you have made edits to the planet information, you will not have the ability to modify it again for a period of {n1} days. Kindly confirm the details before proceeding. ",
    desc21: "Planet Name",
    desc22:
      "Give your planet a unique and meaningful name! This will become the unique identity of your planet.",
    desc23: "Planet Manifesto",
    desc24:
      "Describe your planet's history, inhabitants, resources and uniqueness. ",
    desc25: "Requirement",
    desc26: "Upload attachments require a size of 140*140",
    desc27: "The file must not be larger than 1MB",
    desc28:
      "Please strictly follow the requirements to upload, the following provides sample attachments:",
    desc29: "Click to Download",
    desc30: "Image Upload",
    desc31: "Cancel",
    desc32:
      "Once we're done editing, we'll do a professional-grade render of your planet. Every detail has been meticulously rendered to ensure your planet stands out in the universe. ",
    desc33: "Updated",
    desc34: "Example",
    desc35: "Cancel",
    desc36: "Preview",
    desc37: "Please enter planet name",
    desc38: "Please enter planet description",
    desc39: "Planet transforming...",
    desc40: "Last step",
    desc41: "Your Planet",
    desc42: "Planet Name",
    desc43: "Confirm",
    desc44: "Successful",
    desc45: "undeveloped",
    desc46: "Land Occupancy",
    desc47: "Unchartered Land",
    desc48: "Tax Today",
    desc49: "Your Planet, Your World!",
    desc50: "Referees",
    desc51: "Referees Subscribed",
    desc52: "Referrer:{n1}",
    desc53: "Accumulate total referees",
    desc54: "Accumulate total referees subscribed",
    desc55: "Need to upload planet pictures",
    desc56: 'Transfer',
    desc57: 'Received',
    desc58: 'Transfer',
    desc59: 'Occupancy',
    desc60: "Receiver's UID",
    desc61: "Enter receiver's UID",
    desc62: 'Invitation Code (Optional)',
    desc63: 'Enter invitation code,',
    desc64: 'Transfer Fees',
    desc65: 'Deposit Account Balance',
    desc66: "I've agreed to the <span style='color:#C9FA5B'>Rules and Regulations</span>",
    desc67: 'To add a new planet referrer, the planet referrer must own a planet (including a Stone Planet), no self-referral is allowed. After entering an invitation code, any new land sales on that planet, the referrer will receive a 0.4% reward of the land purchase amount.',
    desc68: 'Rules and Regulations',
    desc69: '1. There are no limits on planet transfers. ',
    desc70: '2. No restrictions on planet types; all planets can be transferred.',
    desc71: '3. Transfer fees are deducted from the sender’s Deposit account (DAOT).',
    desc72: '4. The initial planet transfer fee is {n1} DAOT, and each subsequent transfer incurs an additional {n2}% on the accumulated transfer fees.',
    desc73: '5.Transfer fees are based on the numbers of transfers of the planet, not the account. ',
    desc74: '6. If the entered invitation code is invalid or empty, the distribution of planet referral rewards is voided until the next valid invitation is input. Subsequently, new referral rewards are allocated to the new planet-referred user.    ',
    desc75: ' 7.The invitation code refers to the new planet-referred user. Any new referral rewards generated from the planet will be distributed to the new referrer.  ',
    desc76: "8. The planet referrer is required to own a planet, including a Stone Planet; the planet receiver and planet owner cannot be the same simultaneously. If you own only one planet, you won't be able to designate yourself as the planet referrer, as the planet referrer must own a planet.",
    desc761: '9.Planet transfer takes effect immediately.',
    desc77: 'Transfer Success',
    desc78: 'Transfer fees depend on the number of planet transfers. With each transfer, the accumulated transfer fee increases by {n1}%.',
    desc79: 'Username <span style="color:#C9FA5B">{n1}</span> had transferred a [{n4}] planet with coordinates [{n3}] to you at {n2} (GST). ',
    desc80: 'Transfer',
    desc81: 'Receive',
    desc82: '{n1} units',
    desc83: 'Planet ',
    desc84: 'Planet',
    desc85: 'Subscribe',
    desc86: 'Receive',
    desc87: 'Transfer Record',
    desc88: 'Transfer-Out',
    desc89: 'Transfer-In',
    desc90: 'Time',
    desc91: 'Type',
    desc92: 'Name',
    desc93: 'Type',
    desc94: 'Coordinates',
    desc95: 'Occupancy',
    desc96: 'Price',
    desc97: 'Transfer Fees',
    desc98: 'To Account',
    desc99: 'Referral',
    desc100: 'Status',
    desc101: 'Completed',
    desc102: 'Transfer-Out',
    desc103: 'Transfer-In',
    desc104: 'From Account',
    desc105: 'Type',
    desc106: 'Manual Transfer',
    desc107: 'System Transfer'
  },
  node: {
    desc1:
      "Join the node market immediately, become a planet holder, and enjoy the right of first refusal and generous land tax. ",
    desc2:
      "Use your planet's land to stabilize wealth growth and open the door to the wealth kingdom! ",
    desc3: "Type",
    desc4: "Price",
    desc5: "Daily Rewards",
    desc6: "Available",
    desc7: "{n1} Planets",
    desc8: "Coordinates",
    desc9: "Level",
    desc10: "Status",
    desc11: "Subscribe",
    desc12: "Filter",
    desc13: "ALL",
    desc14: "On Sale",
    desc15: "Sold",
    desc16: "Name",
    desc17: "Coodinates number",
    desc18: "Subscribe [{n1}]",
    desc19: "Coordinates",
    desc20: "Planet Type",
    desc21: "Planet Price",
    desc22: "Total Land",
    desc23: "Random",
    desc24: "Subscribe",
    desc25: "Please name this planet",
    desc26: "Payment Method",
    desc27: "Deposit Account",
    desc28: "Rewards Account",
    desc29: "Payment Token",
    desc30: "{n1} Payment",
    desc31:
      "subscribe this planet, you will have the sales and naming rights of this planet. Everyone can know that this planet is named after you, and every time your own planet is subscribed with a piece of land, you will get land tax. ",
    desc32: "Confirm Payment",
    desc33: "Upcoming",
    desc34: "Activated",
    desc35: "On Sale",
    desc36: "Low to High",
    desc37: "High to Low",
    desc38: "Land Investment Range",
    desc39: "Land Tax",
    desc40: "Planet Naming Fee",
    desc41: "Payment Account",
    desc42: "Deposit now",
    desc43:
      'By subscribing, you acknowledge that you have read and agreed to the product <span style="color:#ffffff;">"Sales Agreement"</span>',
    desc44: "Pay {n1}",
    desc45: "Please set your transaction password first",
    desc46:
      "For the security purposes of your funds, a transaction password is required for transactions within the platform. Since you have not set a transaction password, please set one before making a purchase.",
    desc47: "Maybe later",
    desc48: "Set Now",
    desc49: "Account not logged in",
    desc50: "Please log in before subscribing.",
    desc51: "Cancel",
    desc52: "Login Now",
    desc53:
      "For the security purposes of your funds, please enter a 6-digit transaction password",
    desc54:
      "Congratulations! You have successfully obtained the right to name a planet.",
    desc55:
      "You may now go to the planet management page to explore and manage your unique planet.",
    desc56: "Continue Exploring",
    desc57: "Edit Planet Now",
    desc58: "Pay Naming Fee",
    desc59: "Welcome to the Planet Editor",
    desc60: "Naming Fee",
    desc61: "Manage My Planets",
    desc62: "Planet Owner",
    desc63: "Available Land",
    desc64: "Land Purchase (soon)",
    desc65: "{n1}'s Planet",
    desc66: "You'll subscribe to【{n1}】",
    desc67: "Available Planet：",
    desc68: "Sold：",
    desc69: "Upcoming",
    desc70: "Celestial",
    desc71: "Celestial Name",
    desc72: "My Planet",
    desc73: "Land subscribed amount * daily rewards *{n1}%",
    desc74: "Land subscribed amount * {n1}%",
    desc75:
      "Note：You can complete the verification process by using the old transaction password from the HyperVerse or Satoshi AI platform",
    desc76: "Invitation Code（Optional）",
    desc77:
      "Referrals are required to own at least <br /> one Planet (including Stone Planet) to <br />receive referral rewards. Referral <br />rewards are calculated based on <br />the land tax*0.4%.",
    desc78: "Insert Invitation Code",
    desc79:
      "Phase 2 of the Planetary Node<br /> subscriptions supports on-chain <br />deposit only",
    desc80: "Available Units",
  },
  new: {
    desc1: "Planet",
    desc2: "Land Recommendation",
    desc3: "Manage",
    desc4: "My Land",
    desc5: "EXCHANGE",
    desc6: "Asset Overview",
    desc7: "Deposit Account",
    desc8: "Rewards Account",
    desc9: "Exchange Account",
    desc10: "Assets Overview:",
    desc11: "Value",
    desc12: "Transfer",
    desc13: "Type",
    desc14: "Quantity",
    desc15: "Released Time",
    desc16: "Status",
    desc17: "Note:",
    desc18:
      "1.DP Points will be generated only from land tax, planet referral rewards, market rewards, 10% daily rewards from the land staking, and daily rewards from the TFA package.",
    desc19: "2.DP Points can only be used to charged the TFA packages.",
    desc20:
      "3.DP Points will only be released if there's a TFA packages is in the charging process.",
  },
  footer: {
    desc1: "Product",
    desc2: "DID",
    desc3: "My Avatar",
    desc4: "My Space",
    desc5: "My Star",
    desc6: "Market",
    desc7: "Support",
    desc8: "FAQ",
    desc9: "Docs",
    desc10: "Contact",
    desc11: "Developer",
    desc12: "Github",
    desc13: "Bugs",
    desc14: "Submit",
    desc15: "Audit",
    desc16: "Other",
    desc17: "Daoversal",
    desc18: "Token",
    desc19: "Privacy Policy",
    desc20: "Terms Of Use",
    desc21: "Socials",
    desc22: "Planet",
    desc23: "Assets",
    desc24: "Invite",
    desc25: "Me",
  },
  point: {
    desc1: "Total {n1} Planet Owners",
    desc2: "description",
    desc3: "You've Subscribed",
  },
  page: {
    desc: "Invitation Code",
    desc1: "Enter Invitation Code",
    desc2: "Please Enter Your Invitation Code",
    desc3: "Ok",
  },
  invite: {
    desc1:
      "Inviting friends to join Daoversal allows you to share the joy of the digital universe with your friends",
    desc2:
      "You can also earn higher rewards through generous incentives. The more friends you invite, the more substantial the rewards will be!",
    desc3: "Invite Now",
    desc4: "Your current ranking: {n1}",
    desc5: "Requirements for the next level",
    desc6: "Progress bar",
    desc7: "T1 Active Users",
    desc8: "T2+3 Active Users",
    desc9: "Max Land Lvl",
    desc10: "Your Team",
    desc11: "View list",
    desc12: "Team Size",
    desc13: "Team Planet",
    desc14: "Team Land",
    desc15: "Team Performance",
    desc16: "Planet Overview",
    desc17: "Active Planet",
    desc18: "Accumulated Staking",
    desc19: "Unchartered Land",
    desc20: "Hinterland",
    desc21: "Developed",
    desc22: "In Progress",
    desc23: "Daoversal Web3 social universe",
    desc24:
      "Invite you to explore the Daoversal Web3 social universe and share the feast of wealth:",
    desc25: "Enter official website",
    dec26: "Bind Referral Code",
    desc27: "Save poster",
    desc28: "Copy invitation link",
    desc29: "My Team",
    desc30: "Yes",
    desc31: "No",
    desc32: "Last login time",
    desc33: "Downline",
    desc34: "Planet Owner",
    desc35: "Land Owner",
    desc36: "Team Performance",
    desc37: "from low to high",
    desc38: "from high to low",
    desc39: "Direct",
    desc40: "Planets Quantity",
    desc41: "Land Quantity",
    desc42: "Team Performance",
    desc43: "View Downline",
    desc44: 'Campaign',
    desc45: 'Invite',
    desc46: 'Rules',
    desc47: 'Leaderboard',
    desc48: 'My Referee',
    desc49: 'My Rewards',
    desc50: 'Invite 3 friends to stake a minimum of 200 DU of Bronze Land or higher ',
    desc51: 'Get Your Share of the 100,000 DU Rewards Pool',
    desc52: 'Campaign Period：2023-10-31 11:00:00 (GST) to 2023-11-10 10:59:59 (GST)',
    desc53: 'Total Pool Participants',
    desc54: 'Total Pool Rewards',
    desc55: 'Campaign',
    desc56: '1. Top-5 highest recruitment users can share 10% of the pool rewards.',
    desc57: '1st',
    desc58: '2nd',
    desc59: '3rd',
    desc60: '4th',
    desc61: '5th',
    desc62: '2. 6th onwards to the last user in the pool will equally share 90% of the pool rewards',
    desc63: '3. Additional Reward: The last five users who join the reward pool will receive an extra reward of 500 DU each.',
    desc64: '{n1} users',
    desc65: '1. Campaign Introduction: Throughout the campaign, participants must invite at least <span style="color:#C9FA5B">3</span> new direct downline users. For a participant to qualify, each of these referred users should subscribe to a minimum of <span style="color:#C9FA5B">200 DU</span> of Bronze land or higher. Qualified participants can then join the 100,000 DU rewards pool. ',
    desc651: '2.  Additional Reward: The last five users who join the reward pool will receive an extra reward of 500 DU each. ',
    desc66: '3. Eligible Participants: All users are eligible .',
    desc67: '4. A new referee must subscribe to at least <span style="color:#C9FA5B">200 DU </span> of land to be considered as 1 valid user.',
    desc68: "5. Reward pool distributions will be credited to the winners' deposit accounts within <span style='color:#C9FA5B'>5 working days</span> after the campaign concludes.",
    desc69: '6. All participants in the pool are eligible to share in the total reward value of <span style="color:#C9FA5B">100,000 DU</span>.',
    desc70: '7. New direct users must register their accounts during the campaign period to qualify.',
    desc71: '8. In the event of a tie among the top 5 winners, priority will be given to those with the highest land staking value from the newly recruited direct users.',
    desc72: 'Leaderboard',
    desc73: 'Ranking',
    desc74: 'UID',
    desc75: 'Total Referees',
    desc76: '*Top-5 highest recruitment users can share 10% of the pool rewards.',
    desc77: '*Updated at 2023-10-27 15:30:23 (UTC+4)。',
    desc78: 'Registration Time',
    desc79: '*Additional Rewards: the last five users who join the reward pool will receive an extra reward of 500 DU each. ',
    desc80: '*Display only new direct users who were invited, registered, and completed a stake of at least 200 DU during the campaign period.',
    desc81: 'Time',
    desc82: 'Rewards Type',
    desc83: 'Token',
    desc84: 'Quantity',
    desc85: 'Status',
    desc86: 'Additional Rewards',
    desc87: 'No',
    desc89: 'Join Rewards Pool',
    desc90: 'Invite',
    desc91: '参与奖池瓜分人数每日 03:00-06:00（GMT+4）更新一次。',
    desc92: 'Team Performance = Downline’s personal performance + Downline’s teams performance',
    desc93: 'Self Planet',
    desc94: 'Self Land',
    desc95: "Team Size",
    desc96: '',
  },
  swap: {
    desc: "Exchange",
    desc1: "Exchange Record",
    desc2: "Transaction Password",
    desc3: "Please enter the transaction password",
    desc4: "Transaction Fee",
    desc5: "Confirm",
    desc6: "Select Token",
    desc7: "Exchange Successful",
    desc8: "Please enter the exchange amount",
    desc9: "Actually Received",
    desc10: "Quota",
  },
  withdraw: {
    desc: "Available：",
    desc1: "Minimum withdrawal {n1} {n2}",
    desc2: "Token Received",
    desc3:
      "System will convert {n1} into the selected token type according to the market rate",
    desc4: "Network",
    desc5: "Withdrawal Address",
    desc6: "Enter or paste address",
    desc7: "Fees",
    desc8: "Actual Received",
    desc9: "Insufficient Balance",
    desc10: "Reminder:",
    desc11:
      "1. Make sure to use the correct network and cryptocurrency address when withdrawing.",
    desc12:
      '2. Withdrawals might take around <span style="color:#c9fa5b;">1-3</span> days to arrive. The confirmation time varies depending on the blockchain and its current network traffic.',
    desc13:
      '3. The minimum withdrawal amount is <span style="color:#c9fa5b;">{n1} {n2}</span>, Withdrawals below this amount will not be processed.',
    desc14:
      '4. Please avoid withdrawing assets to addresses not compatible with the <span style="color:#c9fa5b;">{n1}-{n2}</span>, as this could lead to permanent loss of assets.',
    desc15:
      "5. Please make sure the address and amount you entered is correct before confirming the transfer.",
    desc16: "Safety Verification",
    desc17: "Email",
    desc18: "Email Verification Code",
    desc19: "Please enter a 6-digits code",
    desc20: "Send",
    desc21: "GA Code",
    desc22: "Please enter a 6-digits code",
    desc23: "Confirm",
    desc24: "Pending",
    desc25: "Failed",
    desc26: "Failed",
    desc27: "Processing",
    desc28: "Callback Failed",
    desc29: "Sucess",
    desc30: "Refunded",
    desc31: "Withdrawal History",
    desc32: "Withdrawal Details",
    desc33: "TXID",
    desc34: "Amount",
    desc35: "Network",
    desc36: "Fees",
    desc37: "Status",
    desc38: "TXID",
    desc39: "Wait",
    desc40: "Success",
    desc41: "Failed",
  },
  land: {
    desc1:
      "Explore these uniquely named planets and start your interstellar journey! ",
    desc2:
      "Choose a planet name that resonates with your soul, and make your planet unique in the universe! ",
    desc3: "Search planet coordinates/holders/planet name",
    desc4: "Holder：",
    desc5: "Land Conditions",
    desc6: "Planet Level",
    desc7: "Planet Colonization",
    desc8: "Subscribe Now",
  },

  node: {
    desc1:
      "Join the node market immediately, become a planet holder, and enjoy the right of first refusal and generous land tax. ",
    desc2:
      "Use your planet's land to stabilize wealth growth and open the door to the wealth kingdom! ",
    desc3: "Type",
    desc4: "Price",
    desc5: "Daily Rewards",
    desc6: "Available",
    desc7: "{n1} Planets",
    desc8: "Coordinates",
    desc9: "Level",
    desc10: "Status",
    desc11: "Subscribe",
    desc12: "Filter",
    desc13: "ALL",
    desc14: "On Sale",
    desc15: "Sold",
    desc16: "Name",
    desc17: "Coodinates number",
    desc18: "Subscribe [{n1}]",
    desc19: "Coordinates",
    desc20: "Planet Type",
    desc21: "Planet Price",
    desc22: "Total Land",
    desc23: "Random",
    desc24: "Subscribe",
    desc25: "Please name this planet",
    desc26: "Payment Method",
    desc27: "Deposit Account",
    desc28: "Rewards Account",
    desc29: "Payment Token",
    desc30: "{n1} Payment",
    desc31:
      "subscribe this planet, you will have the sales and naming rights of this planet. Everyone can know that this planet is named after you, and every time your own planet is subscribed with a piece of land, you will get land tax. ",
    desc32: "Confirm Payment",
    desc33: "Upcoming",
    desc34: "Activated",
    desc35: "On Sale",
    desc36: "Low to High",
    desc37: "High to Low",
    desc38: "Land Investment Range",
    desc39: "Land Tax",
    desc40: "Planet Naming Fee",
    desc41: "Payment Account",
    desc42: "Deposit now",
    desc43:
      'By subscribing, you acknowledge that you have read and agreed to the product <span style="color:#ffffff;">"Sales Agreement"</span>',
    desc44: "Pay {n1}",
    desc45: "Please set your transaction password first",
    desc46:
      "For the security purposes of your funds, a transaction password is required for transactions within the platform. Since you have not set a transaction password, please set one before making a purchase.",
    desc47: "Maybe later",
    desc48: "Set Now",
    desc49: "Account not logged in",
    desc50: "Please log in before subscribing.",
    desc51: "Cancel",
    desc52: "Login Now",
    desc53:
      "For the security purposes of your funds, please enter a 6-digit transaction password",
    desc54:
      "Congratulations! You have successfully obtained the right to name a planet.",
    desc55:
      "You may now go to the planet management page to explore and manage your unique planet.",
    desc56: "Continue Exploring",
    desc57: "Edit Planet Now",
    desc58: "Pay Naming Fee",
    desc59: "Welcome to the Planet Editor",
    desc60: "Naming Fee",
    desc61: "Manage My Planets",
    desc62: "Planetary Node",
    desc63: "Available Land",
    desc64: "Land Purchase (soon)",
    desc65: "{n1}'s Planet",
    desc66: "You'll subscribe to【{n1}】",
    desc67: "Available Planet：",
    desc68: "Sold：",
    desc69: "Upcoming",
    desc70: "Celestial",
    desc71: "Celestial Name",
    desc72: "My Planet",
    desc73: "Land subscribed amount * daily rewards *{n1}%",
    desc74: "Land subscribed amount * {n1}%",
    desc75:
      "Note：You can complete the verification process by using the old transaction password from the HyperVerse or Satoshi AI platform",
    desc76: "Invitation Code（Optional）",
    desc77:
      "Referrals are required to own at least <br /> one Planet (including Stone Planet) to <br />receive referral rewards. Referral <br />rewards are calculated based on <br />the land tax*0.4%.",
    desc78: "Insert Invitation Code",
    desc79:
      "Phase 2 of the Planetary Node<br /> subscriptions supports on-chain <br />deposit only",
    desc80: "Available Units",
  },
  footer: {
    desc1: "Product",
    desc2: "DID",
    desc3: "My Avatar",
    desc4: "My Space",
    desc5: "My Star",
    desc6: "Market",
    desc7: "Support",
    desc8: "FAQ",
    desc9: "Docs",
    desc10: "Contact",
    desc11: "Developer",
    desc12: "Github",
    desc13: "Bugs",
    desc14: "Submit",
    desc15: "Audit",
    desc16: "Other",
    desc17: "Daoversal",
    desc18: "Token",
    desc19: "Privacy Policy",
    desc20: "Terms Of Use",
    desc21: "Socials",
    desc22: "Planet",
    desc23: "Assets",
    desc24: "Invite",
    desc25: "Me",
  },
  point: {
    desc1: "Total {n1} Planet Owners",
    desc2: "description",
    desc3: "You've Subscribed",
  },
  page: {
    desc: "Invitation Code",
    desc1: "Enter Invitation Code",
    desc2: "Please Enter Your Invitation Code",
    desc3: "Ok",
  },
  swap: {
    desc: "Exchange",
    desc1: "Exchange Record",
    desc2: "Transaction Password",
    desc3: "Please enter the transaction password",
    desc4: "Transaction Fee",
    desc5: "Confirm",
    desc6: "Select Token",
    desc7: "Exchange Successful",
    desc8: "Please enter the exchange amount",
    desc9: "Actually Received",
    desc10: "Quota",
  },
  myincome: {
    desc: "To Collect",
    desc1: "Last Released:",
    desc2: "Land Rewards",
    desc3: "Land Tax",
    desc4: "Generative Rewards",
    desc5: "Collect Now",
    desc6: "Overview",
    desc7: "Total Staked",
    desc8: "Owned Land",
    desc9: "Owned Planet",
    desc10: "Total Loss",
    desc11: "Total Rewards",
    desc12: "Collected",
    desc13: "To Collect",
    desc14: "Type",
    desc15: "Referral Rewards",
    desc16: "Token",
    desc17: "Rewards Date",
    desc18: "Collect Time",
    desc19: "Quantity",
    desc20: "Status",
    desc21: "Account",
    desc22: "Operation",
    desc23: "Total collected rewards",
    desc24: "Total collected rewards",
    desc25: "Total collected Generative Rewards",
    desc26: "Total collected land tax",
    desc27:
      "Reminder: Rewards that haven't been collected for over 120 hours will cease to generate until the next collection.",
    desc28:
      "Except for Stone Land, rewards for all other lands are limited to three times the principal stake amount. (Total Rewards=Stake amount * 3).Eg: Rewards quota: 50/300,The first value=distributed rewards (including losses),The second value= total rewards.",
    desc29: "Land Rewards (Non-Stone Age)",
    desc30: "Land Tax",
    desc31: "Referral Rewards",
    desc32: "Generative Rewards",
    desc33: "Lost",
    desc34: "Land Rewards (Stone Age)",
    desc35: "To Collect",
    desc36: "Collected",
    desc37: "Lost",
    desc38: "Deposit Accoun",
    desc39: "Rewards Account",
    desc40: "over",
    desc41:
      "Rewards that haven't been collected for over <span style='color: #C9FA5B;'>120 hours</span> will cease to generate until the next collection.",
    desc42: "Got it",
    desc43: "Check Assets",
    desc44: "Release Date",
    desc45: "Reason",
    desc46: "Not Collected",
    desc47: "Planet Name",
    desc48: "Coordinate",
    desc49: "Land Rank",
    desc50: "Stake Amount",
    desc51: "Rate",
    desc52: "Status",
    desc53: "Tier",
    desc54: "Total Users",
    desc55: "Total land income",
    desc56: "Tier-{n1}",
    desc57: "Revenue Details",
    desc58: "Release Date：",
    desc59: "Total Rewards：",
    desc60: "My Rank:",
    desc61: "Tier Entitlement:",
    desc62: "Stake Amount:",
    desc63: "Congrats, You've successfully collect your rewards!",
    desc64: "Land Rewards",
    desc65: "Land Tax",
    desc66: "Referral Rewards",
    desc67: "Market Rewards",
    desc68: "TFA daily rewards",
    desc69: "Accelerate the release of activity rewards",
    desc70: "Business type",
    desc71: "V1",
    desc72: "V2",
  },
  record: {
    desc: "Record Details",
    desc1: "Start Time",
    desc2: "To",
    desc3: "End Time",
    desc4: "Type",
    desc5: "Time",
    desc6: "Token",
    desc7: "Created Date",
    desc8: "Type",
    desc9: "Address",
    desc10: "Quantity",
    desc11: "Order Number",
    desc12: "Status",
    desc13: "No Data Yet",
    desc14: "ALL",
    desc15: "View",
    desc16: "OrderID",
    desc17: "Deposit Address",
    desc18: "Token",
    desc19: "Amount",
    desc20: "Mainnet",
    desc21: "Success",
    desc22: "Amount",
    desc23: "Transaction Time",
  },
  asset: {
    desc: "My Assets",
    desc1: "Withdraw",
    desc2: "Deposit",
    desc3: "Exchange",
    desc4: "Detailed Records",
    desc5: "Invite Friends",
    desc6: "Total Assets",
    desc7: "Detailed Records",
    desc8: "Deposit Record",
    desc9: "Withdrawal History",
    desc10: "Exchange Record",
    desc11: "Token",
    desc12: "Balance",
    desc13: "Ratio",
    desc14: "Deposit",
    desc15: "Asset Overview",
    desc16: "Select Token",
    desc17: "Token",
    desc18: "Transfer To",
    desc19: "Network",
    desc20: "Use the trading platform to scan the code",
    desc21: "Save QR Code",
    desc22: "Deposit Reminder:",
    desc23: "Value: {n1} USDT",
    desc24:
      "2. Do not deposit non-USDT assets to the above address, otherwise, the assets will not be retrievable;",
    desc25:
      "3. Please deposit to the corresponding address by using the third-party platform. ",
    desc26: "Deposit Record",
    desc27: "Select Network",
    desc28: "Select Token",
    desc29: "Notes",
    desc30:
      "For the deposit and withdrawal of digital Token, due to the irreversible and anonymous characteristics of blocknetwork transactions, once you enter wrong information, your assets may not be retrieved. Please proceed with caution. ",
    desc31: "Withdraw",
    desc32: "Withdrawal Record",
    desc33: "Please enter the address",
    desc34: "Withdrawal Amount",
    desc35: "Quantity",
    desc36: "Please enter the amount of assets to be withdrawn",
    desc37: "Security",
    desc38: "Transaction Password",
    desc39: "Please enter the transaction password",
    desc40: "Google ATM",
    desc41: "Please enter the Google Authenticator password",
    desc42: "Balance",
    desc43: "Maximum Withdrawal",
    desc44: "Minimum Withdrawal",
    desc45: "Minimum",
    desc46: "Transaction Fee",
    desc47: "Actually Received",
    desc48: "Submitted Successfully",
    desc49: "Download Succeeded",
    desc50: "Deposit Address",
    desc51: "Copy Address",
    desc52: "Deposit Account",
    desc53: "On-chain Deposit",
    desc54: "Token Name",
    desc55: "Select Deposit Network",
    desc56: "Deposit Token",
    desc57: "Deposit {n2} will be automatically converted into {n1} token",
    desc58: "Deposit Address",
    desc59: "Search",
    desc60:
      "1. Please refrain from depositing any digital assets other than the token you have selected into the above address. Otherwise, you may permanently lose your assets.",
    desc61:
      "2. Please ensure that the selected network matches the network of the platform from which you are withdrawing funds or depositing funds. Selecting the wrong network may result in the loss of your funds. ",
    desc62:
      "3. Your deposit address won't change frequently. In case of any changes, we will notify you through announcements or email.",
    desc63:
      "4. Please ensure that your computer and browser are secure, and that your information is protected from tampering or leaks.",
    desc64:
      "(If you intend to transfer funds from your wallet to our BEP-20 address, kindly ensure that the withdrawal is also executed through the BEP-20 network.)",
    desc65:
      "6. Please send the client or online wallet the corresponding amount of token you need to deposit to this address. ",
    desc66: "Check More",
    desc67: "On-chain Withdrawal",
    desc68: "Select The Token You Need To Withdraw",
    desc69: "Select Network/Address",
    desc70: "Enter or paste withdrawal address",
    desc71: "Withdrawal Amount",
    desc72: "Minimum Withdrawal {n1} {n2}",
    desc73:
      "When withdrawing DU, system will automatically convert it into USDT corresponding to the main network",
    desc74: "Available Balance",
    desc75: "Confirm Withdrawal",
    desc76: "Maximum Withdrawal {n1} {n2}",
    desc77: "Note",
    desc78: "Withdrawal Address",
    desc79: "Naming Planet",
    desc80: "Details",
    desc81: " Last 10 deposits records",
    desc82: "Reminder",
    desc83: "Deposit Details",
    desc84: "Assets Details",
    desc85: "{n2}-{n1}",
    desc86: "Order Details",
    desc87: "Reset",
    desc88: "Search",
    desc89: "Outflows",
    desc90: "Inflows",
    desc91: "Transaction Success",
    desc92: "Network",
    desc93: "From Address",
    desc94: "Deposit Amount",
    desc95: "Amount Received",
    desc96: "Receiving Address",
    desc97: "Received Time",
    desc98: "TXID",
    desc99: "Deposit- Satoshi AI",
    desc100: "Deposit Account",
    desc101: "Rewards Account",
    desc102: "My Rewards",
    desc103: "Deposit",
    desc104: "Total Assets",
    desc105: "Asset List",
    desc106: "Total Amount",
    desc107: "Available",
    desc108: "Frozen",
    desc109: "Total Assets (USDT)",
    desc110: "Total Assets",
    desc111: "Total Assets",
    desc112: "Asset Distribution",
    desc113:
      "Asset distribution: Percentage of different asset types in the account value, converted to USDT.",
    desc114: "Transfer from",
    desc115: "Transfer Token",
    desc116: "Transfer Platform",
    desc117:
      "{n1} assets supported inter-transfer from HyperCommunity platform only ",
    desc118: "Account",
    desc119: "Copy UID",
    desc120:
      "Please enter the receiver's UID when transferring {n1} assets from the HyperCommunity platform.",
    desc121:
      "1. Only {n1} assets from HyperCommunity can be transferred to this platform.",
    desc122: "2. The transferred {n1} can solely subscribe to TFA packages.",
    desc123:
      "4. Once the transfer is completed, the system will automatically credit the corresponding DU credits to your account.",
    desc124: "Transfer record",
    desc125: "Order ID",
    desc126: "Time",
    desc127: "Type",
    desc128: "From UID",
    desc129: "Source Platform",
    desc130: "From Email",
    desc131: "Token",
    desc132: "Quantity",
    desc133: "Status",
    desc134: "From-HYPERCOMUNITY",
    desc135: "Transfer Details",
    desc136: "Swap",
    desc137:
      "3. A Daoversal account can only receive once DU credits from HV or HN account each.",
    desc138:
      "DP Points can be used for charging to the TFA package subscribed only",
    desc139: "Come soon",
    desc140: 'PIN Verification ',
    desc141: 'Email Verification',
    desc142: 'Transaction Password',
    desc143: 'Please enter transaction password',
    desc144: 'For your account security, set up Google Authenticator before withdrawing funds.',
    desc145: '{n1}% StarDUst Reward',
    desc146: 'StarDUst Reward Campaign',
    desc147: 'Users who deposit {n1} during the campaign period will receive an instant {n2}% {n4} reward credited to their {n3}. ',
    desc148: 'Ongoing',
    desc149: 'Campaign Period:',
    desc150: 'Rules',
    desc151: '1. Campaign Period:',
    desc152: '2. Rules: Limited to {n1} deposits only',
    desc153: '3. Reward Calculation: Deposit Amount ({n2})*{n1}%',
    desc154: '4. Reward Type: {n1}',
    desc155: '5. Reward Account: {n1}',
    desc156: '6. Reward Distribution: Instantly credited after successful deposit; details can be viewed in the deposit account assets details.',
    desc157: 'Rules',
    desc159: 'StarDUst Reward：',
    desc160: 'TXID',
    desc161: 'Set DAO VERSA username',
    desc162: 'Not set',
    desc163: 'Set up',
    desc164: 'For the security of your account, please set your mailbox before setting the login password',
    desc165: 'Successfully set username',
    desc166: 'This username is your unique identifier on the DAO VERSAL platform and can be used for multiple functions such as account login and query',
    desc167: 'User name',
    desc168: 'Up to 16 characters long, supports letters, numbers, and special symbols',
    desc169: 'Attention: The user name will accompany you for life. Once it is set to be immutable, please choose and remember your username carefully',
    desc170: 'Set email',
    desc171: 'Set login password',
    desc172: 'Password',
    desc173: 'Please enter the verification code',
    desc174: 'Email settings successful',
    desc175: 'Please enter your login password',
    desc176: 'Please enter your login password again',
    desc177: 'Successfully set login password',
  },
  assetbag: {
    desc1: "TFA",
    desc2: "Overview",
    desc3: "TFA Package",
    desc4: "My TFA Package",
    desc5: "Total Subscribed({n})",
    desc6: "TFA Package",
    desc7: "Total Rewards({n})",
    desc8: "Total Withdrawal({n})",
    desc9: "已分享(個)",
    desc10: "Charged({n})",
    desc11: "Lost({n})",
    desc12: "Rewards Record",
    desc13: "Charge Record",
    desc14: "Check",
    desc15: "Total Rewards",
    desc16: "Value",
    desc17: "Multiplier",
    desc18: "{n1}and{n2}",
    desc19: "Subscribe",
    desc20: "名額分享",
    desc21: "Quotas",
    desc22: "庫存(份)",
    desc23: "Unlimited",
    desc24: "Total Charged",
    desc25: "Charge Rate",
    desc26: "Release Rate",
    desc27: "Charge",
    desc28: "Withdraw",
    desc29: "Details",
    desc30: "Withdrawn",
    desc31: "Available",
    desc32: "可分享(個)",
    desc33: "Pack A",
    desc34: "Pack B",
    desc35: "Pack C",
    desc36: "總數(份)",
    desc37: "Hide",
    desc38: "More",
    desc39: "TFA Package Subscription",
    desc40: "Payment Account",
    desc41: "Account Balance",
    desc42: "Cancel",
    desc43: "Subscribe Now",
    desc44: "Note: Each account can only purchase one {n1}.",
    desc45: "Charge Details",
    desc46: "Charge Balance",
    desc47: "目前每日可領取",
    desc48: "註: 回填率 100%之前是 0.2，之後是 0.6%",
    desc49: "Charge Amount",
    desc50: "Balance Insufficient",
    desc51: "Note:",
    desc52:
      "1.You can only purchase the next TFA Package after achieving a 100% charging rate.",
    desc53:
      "2.DP points rewards will not be released if there's no TFA packages is not in the charging process.",
    desc54: "Total Charged {n1} {n2}",
    desc55: "Charge Rate: {n} ",
    desc56: "Available to withdraw",
    desc57: "TFA Package",
    desc58: "Status",
    desc59: "100% Charged",
    desc60: "Charging",
    desc61: "Available Withdraw",
    desc62: "Total Withdraw",
    desc63: "Balance Withdraw",
    desc64: "Purchase Time",
    desc65: "Withdrawal Confirmation",
    desc66: "Type",
    desc67: "Quantity",
    desc68: "Token",
    desc69: "Confirm",
    desc70: "Rewards Withdrawal Successful",
    desc71: "Got it",
    desc72: "Check Rewards",
    desc73: "Withdrawal Details",
    desc74: "Total Rewards",
    desc75: "Collected",
    desc76: "To Collect",
    desc77: "Pending Rewards",
    desc78: "Date",
    desc79: "Withdrawal Details",
    desc80: "Subscription Successful!",
    desc81: "You've subscribed 1 {n} TFA packagee",
    desc82: "On Sale",
    desc83: "Sold Out",
    desc84: "Charging",
    desc85: "Completed",
    desc86: "Payment Amount",
    desc87: "Complete {n} of the charging rate to activate withdrawal",
    desc88: "Account Balance({n})",
    desc89: "Loss({n})",
    desc90: "Charge Successful!",
    desc91: "You've charged {n} DP to your TFA Package.",
    desc92: "Charge Details",
    desc93: "Quantity",
    desc94: "TFA Package",
    desc95: "Total Rewards",
    desc96: "Type",
    desc97:
      "1. You can only purchase the next TFA Package after achieving a 100% charging rate.",
    desc98: "2. DP Points can only be used to charged the TFA packages.",
    desc99:
      "3. DP Points will only be released if there's a TFA packages is in the charging process.",
    desc100:
      "4. DP Points that have not been claimed for more than 120 hours or have not been used for charging for more than 24 hours will be lost.",
    desc101: "TFA Charge",
    desc102: "Success",
    desc103: "Total subscribed TFA pack value",
    desc104:
      "First value refers to total withdrawn amount {n}, second value refers to balance available amount {n}",
    desc105: "Total withdrawn amount of {n}",
    desc106: "Total charged amount  of {n}",
    desc107: "Total withdrawn amount of {n}",
    desc108:
      "TFA package subscription by {n} only，{n} can be transferred from the HyperCommunity platform",
    desc109:
      "Market rewards DP points affected by the package type market multiplier, Market rewards (DP)=Market rewards*market multiplier",
    desc110: "The higher the charging rate, the higher the withdrawal rate",
    desc111: "Maximum limits for each account to subscribe",
    desc112: "Daily subscription limits",
    desc113: "Total charged amount of {n}",
    desc114: "Total charged amount {n} / Total Package Price",
    desc115: "The higher the charging rate, the higher the withdrawal rate",
    desc116: "Withdrawn amount{n}",
    desc117: "Available withdraw rewards amount{n}",
    desc118:
      "All {n} Points will be lost if not charged to the TFA package within 24 hours.",
    desc119: "Charge Now",
    desc120: "Collection Time",
    desc121: "TFA rewards",
    desc122:
      "If you don't collect your DP Points within 120 hours or charge them within 24 hours, you'll lose the DP points.",
    desc123: "Withdraw",
    desc124: "No withdrawable rewards",
    desc125: "Please subscribe a TFA package to charge",
    desc126: "TFA package has reaches the maximum subscribe limits",
    desc127: "Details",
    desc128: "Progress",
    desc129: "Charge Progress",
    desc130: "Withdrawal Progress",
    desc131: "Withdrawn",
    desc132: "Available",
    desc133: "Balance",
    desc134: "Use Now",
    desc135: "{n} Balance Insufficient",
    desc136: "DU Points",
    desc137: "Transfer-In",
    desc138: "Complete {n} of the charging rate to activate withdrawal",
    desc139: "Withdraw Activation",
    desc140:
      "1.You can initiate the first withdrawal once the charging rate reaches {n}.",
    desc141:
      "2.Before reaching a {n1} charging rate, the daily withdrawal amount will be release at a rate of {n2}* the total accumulated charged amount.",
    desc142:
      "3.After reaching a {n1} charging rate, the daily withdrawal amount will be released at a rate of {n3} * {n2}.",
    desc143: "Withdrawal Progress",
    desc145: "Withdrawn",
    desc146: "Available",
    desc147: "Balance",
    desc148:
      "DP Points will be generated only from <br />land tax, planet referral rewards, market <br />rewards, 10% daily rewards from the <br />land staking, and daily rewards from <br />the TFA package.DP Points can only<br /> be used to charged the TFA packages.<br />DP Points will only be released if there's <br />a TFA packages is in the charging process.",
    desc149: "Use Now",
    desc150: `You have <span>{n}</span> points in your account that have not been used. If you do not use these points within <span>24 hours</span>, they will be lost.`,
    desc151: "Release Rate",
    desc152:
      "{n1}% & {n2}% = TFA package release rate, once the TFA package is 100% charged, the release rate will be upgraded to {n2}%",
    desc153:
      "You can only purchase the next TFA Package after achieving a 100% charging rate",
    desc154:
      "For more information, please read to our <a href='https://daoversal.zendesk.com/hc/en-us/articles/7994766038159-TFA-Package-Subscription-Tutorial' target=_blank>TFA tutorial article</a>.",
    desc155: "Proceed to Rewards Account to check rewards",
    desc156: "100% Charged",
  },
  pool: {
    desc: "To Be Claimed",
    desc1: "Staked Assets (RP)",
    desc2: "Staked Amount",
    desc3: "Land Tax",
    desc4: "Referral Rewards",
    desc5: "Community Rewards",
    desc6: "Claim Rewards",
    desc7: "Staking Pool",
    desc8:
      "Choose the land Staked pool you need in exchange for different Staking rewards on the platform",
    desc9: "Stake Land, Earn Resource Rewards",
    desc10: "Staked Amount",
    desc11: "APR",
    desc12: "Staking Period",
    desc13: "{n1} Days",
    desc14: "Resource Type",
    desc15: "Planet ID",
    desc16: "Land Subscribe",
    desc17: "Celestial Coordinate",
    desc18: "Owned",
    desc19: "Land Subscribe",
    desc20: "{n1} {n2} Subscribe {N3} Pieces",
    desc21: "The remaining land on this planet",
    desc22: "Custom Quantity",
    desc23: "Land Quantity",
    desc24: "Total Price",
    desc25: "Subscribe Now",
    desc28: "Subscribe",
    desc29: "Details Of The Staking Pool",
    desc30: "Land",
    desc31: "You can earn extra money while maintaining your land title",
    desc32:
      "It has been detected that you currently have a planet/land that can be Staked",
    desc33: "Stake Now",
    desc34: "Land Staked Quantity",
    desc35: "Land Converted Into Assets",
    desc36: "Dynamic Staking Pool",
    desc37: "{n1}Days Staking Pool",
    desc38: "ID",
    desc39: "Land Level",
    desc40: "Start Time",
    desc41: "End Time",
    desc42: "Redeem",
    desc43: "Staking",
    desc44: "Not Redeemed",
    desc45: "Redeemed",
    desc46: "Last 7 Days",
    desc47: "Last 15 Days",
    desc48: "Last 30 Days",
    desc49: "Last 90 Days",
    desc50: "Last 180 Days",
    desc51: "Last 365 Days",
    desc52: "Available Land",
    desc53: "Currently Staking「{n1}」",
    desc54: "Selected",
    desc55: "Stake All",
    desc56: "Remaining Balance Amount",
    desc57: "{n1} Rewards",
    desc58: "Please select the land to stake",
    desc59: "Land Stake Successful",
    desc60: "Do you want to redeem the land? ",
    desc61:
      'Are you sure the redemption unit price is <span style="color:#fff;">{n1}{n2}</span>「#{n3}_{n4}」land? ',
    desc62: "Redeem Now",
    desc63: "Redemption Succeeded",
    desc64: "Overview Of Land Rewards",
    desc65: "Claimed",
    desc66:
      "You may choose the corresponding payment to subscribe to this land",
    desc67: "{n1} Balance:",
    desc68: "Insufficient Balance",
    desc69:
      "You may choose the corresponding payment to reassessment this land",
    desc70: "Compound Interest Rewards",
    desc71: "Global Rewards",
    desc72: "You have subscribed to the land",
    desc73: "Go to Dynamic Staking Pool to stake! ",
    desc74: "Stake Now",
    desc75: "Manage",
    desc76: "Pool",
    desc77: "Claim Resource",
    desc78: "Land Status",
    desc79: "Activity",
    desc80: "Named Resource",
    desc81: "Resource:",
    desc82: "Daily Release Rate:",
    desc83: "Weekly Rewards:",
    desc84: "Claim",
    desc85: "Unstake",
    desc86: "Unstake Now? ",
    desc87:
      "A 5% penalty will be imposed on the remaining resources of each piece of land that stops Staking",
    desc88: "Remaining Resources",
    desc89: "Token ID",
    desc90: "Choose To Stop",
    desc91: "All Stopped",
    desc92: "Subscribe {n1} Blocks",
    desc93: "ALL",
    desc94: "Activity",
    desc95: "Art Staking",
    desc96: "I've Selected",
    desc97: "All Of Me",
    desc98: "A Floating APR Is",
    desc99: "Remaining Resources",
    desc100: "Inactive",
    desc101: "Active",
    desc102: "Named Resource",
    desc103: "Select Land",
    desc104: "Proceed to Subscribe ",
    desc105: "Land Price",
    desc106: "To be staked",
  },
  planet: {
    desc: "Type",
    desc1: "Choose",
    desc2: "Status",
    desc3: "Time",
    desc4: "Switch To Series List",
    desc5: "Residual Rewards",
    desc6: "Planet Number",
    desc7: "Planet Level",
    desc8: "Land Reassessment",
    desc9: "Transfer",
    desc10: "Switch To Land List",
    desc11: "Collected",
    desc12: "Staked",
    desc13: "Details",
    desc14: "Transfer of Land",
    desc15: "Address",
    desc16: "Please Enter The Address",
    desc17: "Please Enter The Transaction Password",
    desc18: "Please Enter The Google Authenticator Password",
    desc19: "Cancel",
    desc20: "Confirm Payment",
    desc21: "Land Reassessment",
    desc22: "Land reassessment from <span>{n1}</span> into <span>{n2}</span>",
    desc23: "Payment Amount",
    desc24: "Land Level",
    desc25: "Rewards Type",
    desc26: "Total Earnings",
    desc27: "Total Amount Of Land",
    desc28: "Subscribe",
    desc29: "Do you want to subscribe a land? ",
    desc30:
      "{n1} will be used after subscribing, click on 'confirm' to proceed",
    desc31: "Back",
    desc32: "Confirm",
    desc33: "Series",
    desc34: "Land",
    desc35: "{N1} Times",
    desc36: "Subscribe Successful",
    desc37: "You have not subscribed any land yet",
    desc38: "Own your Daoversal land now! ",
    desc39: "View Galaxy",
    desc40: "ALL",
    desc41: "Staked",
    desc42: "Unstake",
    desc43: "ID",
    desc44: "Price descending",
    desc45: "Price Ascending",
    desc46: "Recipient's ID",
    desc47: "Please Enter The Recipient's Username",
    desc48: "Transfer Succeeded",
    desc49: "Reassessment Succeeded",
    desc50: "Payment Succeeded",
    desc51:
      'Congratulations! You have successfully subscribed "{n1}" land {n2} blocks',
    desc52: "View My Land",
    desc53: "Transfer Record",
    desc54: "Land Image",
    desc55: "Land Number",
    desc56: "Receiving Address",
    desc57: "Transfer Address",
    desc58: "Transfer ID",
    desc59: "Receive ID",
    desc60: "Transfer Time",
    desc61: "Copied",
    desc62: "Land Status",
    desc63: "Staking",
    desc64: "More",
    desc65: "Hide",
    desc66: "Land Market",
    desc67: "Upcoming",
    desc68: "Activated",
    desc69: "On Sale",
  },
  home: {
    desc: "Home",
    desc1: "My Land",
    desc2: "Assets",
    desc3: "Exchange",
    desc4: "Land Market",
    desc5: "Staking Pool List",
    desc6: "Invite",
    desc7: "Transaction Password",
    desc8: "Google Authenticator",
    desc9: "Help Center",
    desc10: "Logout",
    desc11: "Set Transaction Password",
    desc12: "New Transaction Password",
    desc13: "Please enter a new transaction password",
    desc14: "Confirm Transaction Password",
    desc15: "Please enter the transaction password again",
    desc16: "Google Authenticator Code",
    desc17: "Please enter the Google Authenticator code",
    desc18: "Node: Please contact customer service to reset password. ",
    desc19: "Wrong Password",
    desc20: "Ok",
    desc21: "The Two Passwords Are Inconsistent",
    desc22: "Set Google Authenticator Code",
    desc23: "Download App",
    desc24: "Scan QR Code",
    desc25: "Bind Google Authenticator",
    desc26: "Please download 'Google Authenticator' in the mobile app store",
    desc27: "Download From",
    desc28: "App Store",
    desc29: "Google Play",
    desc30: "Bind by scanning the QR code with Google Authenticator",
    desc31: "Copy Key",
    desc32: "Enable Authenticator by verifying your account",
    desc33: "Unbind Google Authenticator Code",
    desc34: "Unbind Via Google Authenticator",
    desc35: "Enter the 6-Digit Verification Code from Google Authenticator",
    desc36:
      "Note: Once you unbind the Google Authenticator code, your account security will drop a lot. It is recommended that you rebind the Google Authenticator code as soon as possible after canceling it to ensure account security. If there is an asset security problem in the account due to personal security reasons, it will have nothing to do with Daoversal. Once unbound, it will be deemed to be at your own risk by default. ",
    desc37: "Unbind",
    desc38: "Settings Completed",
    desc39: "Login has expired, please log in again",
    desc40: "Subscribe",
    desc41:
      "You have been unbound. It is recommended that you rebind the Google Authenticator code as soon as possible to ensure account security. ",
    desc42:
      "Congratulations! You Have Successfully Bound The Google Authenticator Code",
    desc43: "The Transaction Password Is Set",
    desc44: "Are you sure you want to log out?",
    desc45: "Cancel",
    desc46: "Yes",
    desc47: "Logout Successful",
    desc48: "Please Set The Transaction Password",
    desc49: "Please Bind The Google Authenticator Code",
    desc50: "Level:",
    desc51: "ID:",
    desc52: "Coordinates:",
    desc53: "Available Land:",
    desc54: "Celestial Coordinate",
    desc55: "Track",
    desc56: "No.",
    desc57: "Star-ID",
    desc58: "Apply",
    desc59: "Clear",
    desc60: "Planet Name:",
    desc61: "Available",
    desc62: "Upcoming",
    desc63: "Land",
    desc64: 'Please set the Google Authenticator',
    desc65: 'Google Authenticator is not set up yet. Please configure Google Authenticator to enable it.',
    desc66: 'GA Verification',
    desc67: 'Email Verification'
  },
  login: {
    desc1: "Login",
    desc2:
      "Choose one of the available wallet providers or create a new one. What is a wallet?",
    desc3: "Login With Metamask",
    desc4: "Walletconnect",
    desc5: "Home",
    desc6: "Market",
    desc7: "Staking Pool",
    desc8: "Assets",
    desc9: "Mine",
    desc10: "Connect Wallet",
    desc11: "Customer",
    desc12: "Network connection timed out...",
    desc13: "Language Selection",
    desc14: "Staking Pool Details",
  },
  notice: {
    desc1: "DAOVERSAL Announcement",
    desc2: "Details",
  },
  market: {
    desc1: "Status",
    desc2: "Type",
    desc3: "Sales Status",
    desc4: "Price",
    desc5: "Rank",
    desc6: "Planet/Holder's Name",
    desc7: "On Sale",
    desc8: "Sold",
    desc9: "Soon",
    desc10: "High-Low",
    desc11: "Low-High",
    desc12: "Coordinate",
    desc13: "Occupancy",
    desc14: "Rank",
    desc15: "Price",
    desc16: "Subscribe",
    desc17: "Soon",
    desc18: "You're subscribing to [{n1}]'s land",
    desc19: "Planet Rank",
    desc20: "Land Rank",
    desc21: "Land Owners",
    desc22: "Completed",
    desc23: "Price",
    desc24: "Payment Account",
    desc25: "Account Balance",
    desc26: "Staking Range",
    desc27: "Stake within {n1}~{n2}",
    desc28: "Balance insufficient",
    desc29: "I have agreed to the terms and conditions",
    desc30: "Subscribe Now",
    desc31: "Sold",
    desc32: "Soon",
    desc33: "Please enter staking amount",
    desc34: "Minimum staking amount: {n1}",
    desc35: "Maximum staking amount: {n1}",
    desc36: "Subscribe Again",
    desc37: "My Land",
    desc38: "You've successfully subscribed this land",
    desc39: 'Proceed to"My Land"to manage land and check rewards.',
    desc40: "Maximum  {n1} {n2} offset payment",
    desc41: "payment amount",
    desc42: '"Sales Agreement"',
    desc43: "Rewards account balance",
    desc44: "Rewards account balance insufficient",
    desc45: "Collective Rewards",
    desc46: "Last Released: ",
    desc47: "Land Rewards ({n1})",
    desc48: "Land Tax ({n1})",
    desc49: "Generative Rewards (RC)",
    desc50: "Details",
    desc51: "Collect Now",
    desc52: "Total Staked ({n1})",
    desc53: "Owned Lands",
    desc54: "Land Rewards ({n1})",
    desc55: "Land Rewards (RC)",
    desc56: "Generative Rewards (RC)",
    desc57: "Land Tax ({n1})",
    desc58: "Total Rewards (RC)",
    desc59: "Total Loss ({n1})",
    desc60: "Total Loss (RC)",
    desc61: "Total collected rewards",
    desc62: "Total collected rewards",
    desc63: "Total collected Generative Rewards",
    desc64: "Total collected land tax",
    desc65:
      "Except for Stone Land, rewards for all other lands <br />are limited to three times the principal stake amount. <br />(Total Rewards=Stake amount * 3).Eg: Rewards quota: <br />50/300,The first value=distributed rewards (including <br /> losses),The second value= total rewards.",
    desc66:
      "Reminder: Rewards that haven't been collected for over <br />120 hours will cease to generate until the next collection.",
    desc67:
      "Reminder: Rewards that haven't been collected for over <br />120 hours will cease to generate until the next collection.",
    desc68: "My Land",
    desc69: "Price",
    desc70: "Subscribe Time",
    desc71: "Oldest- Newest",
    desc72: "Newes- Oldest",
    desc73: "{n1}'s Land",
    desc74: "Activated on {n1} ",
    desc75: "Pending",
    desc76: "Activating",
    desc77: "Successful",
    desc78: "Abandon",
    desc79: "Period",
    desc80: "Stake Amount",
    desc81: "Unstake",
    desc82: "Add On",
    desc83: "Details",
    desc84: "Land Redemption",
    desc85: "You're redeeming land from {n1}'s Planet",
    desc86: "Planet Coordinate",
    desc87: "Redeem Amount",
    desc88: "Transaction Fees ({n1}%)",
    desc89: "Actual Redeem",
    desc90:
      "1. One-time subscription for Stone Age land per user; re-subscription unavailable after redemption.",
    desc91: "2. {n1}3% redemption fee applies to all redemptions.",
    desc92:
      "3. On the redemption day, land rewards cease; redeemed amount will be credited to your rewards account. ",
    desc93: "Cancel",
    desc94: "Confirm",
    desc95: "Reminder",
    desc96: "Redeem successful",
    desc97: "Land from {n1}'s Planet",
    desc98: "Planet Coordinate",
    desc99: "Current Staking Amount",
    desc100: "Add On Amount",
    desc101: "MAX",
    desc102:
      "Note: When increasiNote: The minimum amount of add-on staking must be {n1} of your current land stake, and the accumulate total staking amount must within the range allowed for the current land type.",
    desc103: "Add On Amount",
    desc104: "Amount {n1} ~ {n2}",
    desc105: "Please enter add-on staking amount",
    desc106: "Add-on staking successful",
    desc107: "Land from {n1}'s Planet",
    desc108: "Effective Time",
    desc109: "Release Rewards",
    desc110: "Staking Range",
    desc111: "Redemption Details",
    desc112: "Redemption Status",
    desc113: "Redeem Time",
    desc114: "Completed Time",
    desc115: "The total number of lands on the current planet is {n1} pieces,There are still {n2} pieces available for sale",
    desc116: "The redeemed land will become abandoned.",
    desc117:
      "When the land has reaches the maximum of 3 times earnings, the land will be marked as completed.",
    desc118: "Referral's Planet",
    desc119: "Team's Planet",
    desc120: "Land",
    desc121: "Please enter your username",
    desc122: "Filter",
    desc123: "Reset",
    desc124: "Overview",
    desc125: "Contact us",
    desc126: "My Account",
    desc127: "Language",
    desc128: "Congrats, You've successfully collect rewards!",
    desc129:
      "Reminder: Rewards that haven't been collected for over <span style='color:#C9FA5B;'>120H</span> will cease to generate until the next collection. ",
    desc130: "Got it",
    desc131: "Check Assets",
    desc132: "More",
    desc133: "Subscribe",
    desc134:
      "This land is not from your referral's /team's planet, are you confirm to proceed it ?",
    desc135: "Collect Now",
    desc136: "Referral Rewards (DU)",
    desc137: "Generative Rewards",
    desc138: "Referral Rewards",
    desc139: "Land Tax",
    desc140: "Land Rewards",
    desc141: "Unstake",
    desc142: "Planet Naming",
    desc143: "Land Subscribe(Sold)",
    desc144:
      "Once unstaked, this account will no longer be able to subscribe to Stone Age Land again, are you confirm to proceed?",
    desc145: "Confirm",
    desc146: "Settlement Rewards",
    desc147: "Add-on Staking ",
    desc148: "Market Rewards",
    desc150: "TFA Subscription",
    desc151: "TFA Charge",
    desc152: "TFA Rewards",
    desc153: "Lost DP- Not Charged ",
    desc154: "Inter-Transfer-hypercomunity ",
    desc155: "Land Rewards",
    desc156: "Land Tax",
    desc157: "Referral Rewards",
    desc158: "Market Rewards",
    desc159: "TFA daily rewards",
    desc160: "Settlement Rewards",
    desc161: "TFA Pre-Launch",
    desc162: 'Halloween- Top 5 Rewards',
    desc163: 'Halloween- Additional Rewards',
    desc164: 'Campaign Rewards',
    desc165: 'Halloween- Pool Rewards',
    desc166: 'StarDUst Reward',
    desc167: 'Mystery Box rewards',
    desc168: 'Weekly Reassessment Rewards',
    desc169: 'Monthly Reassessment Rewards',
    desc170: 'Planet Achiever Rewards',
    desc171: 'Transfer Fees',
    desc172: '1. The minimum amount of add-on staking must be more than 10% of your current land stake. ',
    desc173: '2. The accumulated staking amount (current land stake + add-on staking)  must be within the land staking range.',
    desc174: 'Note: ',
    desc175: 'Accelerating',
    desc176: 'Planet Occupancy Rate  {n1}%',
    desc177: 'Instructions',
    desc178: '1. Subscription Percentage: Land Sold/Total Parcels on a Planet',
    desc179: '2. Land Booster Campaign: When a Planet achieves a percentage of Land Subscription, both the Planetary Node and the Land Subscribers will receive rewards; in specific, a 30% Land Subscription Percentage enables an extra 0.2% release rate to Land Subscribers of that Planet for 10 days; 50% Land Subscription Percentage enables an extra 0.2% release rate to Land Subscribers of that Planet for another 10 days, etc. as follows:',
    desc180: 'Land Subscription Percentage',
    desc181: 'Land Subscriber Reward',
    desc182: 'Acceleration Time',
    desc183: 'Planetary Node Reward Ratio',
    desc184: 'Land Booster Campaign',
    desc185: 'Reward Type',
    desc186: 'Land Boosted Rewards',
    desc187: 'Details',
    desc188: 'Rules',
    desc189: '1. Campaign Name: Land Booster Campaign',
    desc190: '2. Campaign Time: ',
    desc191: '3. Description: When a Planet achieves a percentage of Land Subscription, both the Planetary Node and the Land Subscribers will receive rewards; in specific, a 30% Land Subscription Percentage enables an extra 0.2% release rate to Land Subscribers of that Planet for 10 days; 50% Land Subscription Percentage enables an extra 0.2% release rate to Land Subscribers of that Planet for another 10 days, etc. as follows:',
    desc192: '4. Campaign Rules:',
    desc193: '4.1 All planet types, including Stone Planet, are eligible for this campaign. ',
    desc194: '4.2 From 30% moving towards 50% Land Subscription Percentage, the Land Subscriber Reward and the Planetary Node Reward will be extended for another 10 days but the release rate is not compounding.',
    desc195: '4.3 From 30% moving towards 100% Land Subscription Percentage, the Land Subscriber Reward and the Planetary Node Reward will be eligible for up to 40 days.',
    desc196: '5. Land Subscriber Reward Calculation: Existing Release Rate + 0.2%',
    desc197: "6. Planetary Node Reward Calculation: Land Subscriber's Daily Reward * Planetary Node Reward Ratio",
    desc198: '7. Land Subscriber Reward Effective Period: T+1',
    desc199: '8. Planetary Node Reward Effective Period: T+1',
    desc200: '9. Example:',
    desc201: 'Example 1: Planet A achieves 300 pieces of Land Subscription (30%), additional 0.2% boosted reward for all Land Subscribers of that Planet will be added on top of the existing release rate, e.g. 0.6% existing (6DU) + 0.2% additional (2U) = 0.8% (8DU), lasting up to 10 days. Simultaneously, the Planetary Node will receive 8DU * 3% = 0.24 DU as the Planetary Node Reward for 10 days.',
    desc202: "Example 2: Planet B achieves 900 pieces of Land Subscription (80%), Land Subscribers will experience a 0.2% acceleration for 30 days, while the Planetary Node will receive a 3% Planetary Node Reward for the initial 10 days , 4% Planetary Node Reward for the next 10 days and another 5% for the subsequent 10 days, derived from the Land Subscribers' Daily Reward.",
    desc203: 'Land Boosted Release',
    desc204: 'Land Booster Campaign',
    desc205: 'When a Planet achieves a corresponding Land Subscription Percentage, both the Planetary Node and the Land Subscribers will receive the boosted release rate and also the reward',
    desc206: 'Accelerated',
    desc207: 'Planetary Node Reward ',
    desc208: 'When a Planet achieves Land<br /> Subscription more than 30%, <br />the Planetary Node will receive <br />extra 3%-6% of Planetary Node <br />Reward for 10 days and more',
    desc209: 'Reminder:',
    desc210: '1. The reward generated from Stone Land will be automatically added to the staking pool, and Land Subscribers can only choose to unstake in order to claim reward',
    desc211: '2. When a Planet achieves Land Subscription more than 30%, Land Subscribers on that particular Planet will receive extra 0.2% of Land Subscriber Reward for 10 days and more',
    desc212: 'Land Boosted',
    desc213: 'Note: When a Planet achieves Land Subscription more than 30%, the Planetary Node will receive 3%-6% of boosted rewards for 10 days and more',
    desc214: 'Total Reward',
    desc215: 'Rewards Ratio',
    desc216: 'Land Booster Campaign',
    desc217: 'Designed to reward Land Subscribers and Planetary Nodes based on land subscription percentage on a Planet.',
    desc218: 'Land Reassessment Campaign',
    desc219: 'Planetary Node Reward DP',
    desc220: 'Accumulate the most land reassessment amount and walk away with lucrative rewards.',
    desc221: 'Starting from {n1} GST (Ongoing)',
    desc222: 'Rollback',
    desc223: 'Withdrawal Cancelled',
    desc224:'Subscribe Quantity',
    desc225:'Available：',
    desc226:'Balance：',
    desc227:"You've reached the maximum number of subscriptions for this  current land type.",
    desc228:'Total days of pledge',
  },
  transfer: {
    desc1: "Inter-Transfer",
    desc2: "Token",
    desc3: "Transfer",
    desc4: "From",
    desc5: "To",
    desc6: "Quantity",
    desc7: "All",
    desc8: "Available ",
    desc9: "Confirm",
    desc10: "Records",
    desc11: "OrderID",
    desc12: "Time",
    desc13: "Transfer Quantity",
    desc14: "Status",
    desc15: "Please enter transfer quantity",
    desc16: "To",
    desc17: "Quantity",
    desc18: "Successful",
    desc19: "Inter",
    desc20: "Transfer",
    desc21: "Details",
  },
  activity: {
    desc1: 'Halloween Recruitment Campaign',
    desc2: 'Check now',
    desc3: 'More'
  },
  brand: {
    desc1: 'component',
    desc2: 'resource',
    desc3: 'A resource library for the community',
    desc4: 'MATERIAL MAP',
    desc5: 'Download resources',
    desc6: 'Design based on ',
    desc7: 'Daoversal',
    desc8: 'Typography',
    desc9: 'Color',
    desc10: 'Planet',
    desc11: 'Land',
    desc12: 'Logo',
    desc13: 'Layout',
    desc14: 'Background',
    desc15: 'Error Demo',
    desc16: 'Safe Space',
    desc17: 'Color',
    desc18: 'Typography',
    desc19: 'The grid design form is selected according to the actual situation of the project to adapt to multiple complex scenarios.',
    desc20: 'This page shows the application rules of background brightness when using brand logos. When the background color brightness is between 10% and 60%, the use of ink-colored logos is prohibited. In other cases, it can be used normally.',
    desc21: 'Do not redraw or outline the logo',
    desc22: "Don't add a shadow to the logo",
    desc23: "Do not change the proportions of the logo",
    desc24: 'Don’t use distorted logos',
    desc25: 'In order to ensure that the brand logo can be clearly recognized in all application scenarios, a certain safe and non-intrusive area needs to be reserved around the logo. When the size of the logo is scaled, the size of the safe area will be scaled proportionally. There should be no text, graphics, images, lines, etc. within the intrespassable area.',
    desc26: '·Sign safe space (grey bottom)',
    desc27: '·Set the logo height to',
    desc28: '·A distance of 0.5X equidistant from the periphery of the sign is the safe space for the sign.',
    desc29: 'brand color',
    desc30: 'Black',
    desc31: 'white',
    desc32: 'yellow',
    desc33: 'grey',
    desc34: 'orange',
    desc35: 'montserrat',
    desc36: 'planeto',
    desc37: 'Light',
    desc38: 'regular',
    desc39: 'semibold',
    desc40: 'Logo',
    desc41: 'Planet',
    desc42: 'Land',
    desc43: 'Auxiliary',
    desc44: 'Flag',
    desc45: 'Lanyard',
    desc46: 'T-Shirt',
    desc47: 'Tote Bag',
    desc48: 'Cap',
    desc49: 'Pen',
    desc50: 'Notepad',
    desc51: 'Water Bottle',
    desc52: 'Brochure',
    desc53: 'Roll Up Bunting',
    desc54: 'Poster',
    desc55: 'Newsletter',
    desc56: 'The logo is in PNG format.',
    desc57: 'The planet is in PNG format.',
    desc58: 'Stone Planet',
    desc59: 'Bronze Planet',
    desc60: 'Iron Planet',
    desc61: 'Industrial Planet',
    desc62: 'Electric Planet',
    desc63: 'Electric Planet',
    desc64: 'AI Planet',
    desc65: 'The land is in PNG format.',
    desc66: 'The auxiliary is in PNG format.',
    desc67: 'The Material is in PSD format.',
    desc68: 'Flag Surface',
    desc69: 'Width：',
    desc70: 'Height：',
    desc71: 'Lanyard Length',
    desc72: 'Kindly take note that all the measurement will be inside the PSD file',
    desc73: 'The Material is in PSD format.',
    desc74: 'Kindly take note that the Material will be in Daoversal’s logo in PNG file',
    desc75: '3-Folded Brochure:',
    desc76: 'Full Size: Width 360mm * Height 210mm',
    desc77: 'Folded Size: Width 90mm * Height 210mm',
    desc78: 'There are 6 design in the file',
    desc79: 'Roll Up Bunting Size:',
    desc80: 'Poster Size: Width 800mm * Height 1800mm',
    desc81: 'The Poster is in JPEG format.',
    desc82: 'The Newsletters is in JPEG format.',
    desc83: 'Resource Library',
    desc84: 'The Material is in PNG format.'
  },
  blind: {
    desc1: 'Reward Centre',
    desc2: 'Join campaign at the rewards centre, more rewards for you to win!',
    desc3: 'Limited Prize, Join Now!',
    desc4: 'Mystery Box',
    desc5: 'Congratulations! You’ve won a Merry Jolly lucky draw chance',

    desc6: 'Subscribe to any land (excluding Stone Land) to draw <span style="color:#C9FA5B">{n1} times</span> of mystery box daily',
    desc7: 'Open Now',
    desc8: 'Rules & Regulations',
    desc9: '1. Campaign Name: Merry Jolly Daily Rewards Campaign',
    desc10: '2. Campaign Period: ',
    desc11: '3. Campaign Description: Subscribe to any land (excluding Stone Land) to enjoy <span style="color:#C9FA5B">{n1} times</span> of mystery box daily.',
    desc12: '4. Eligible Users： Subscribers of any land type from Bronze up to Electric (excluding Stone Land).',
    desc13: '5.  New Users: T+1 upon subscribing to a new piece of Bronze Land and beyond.',
    desc14: '6.  Land Types Requirement: All land types (excluding Stone Land).',
    desc15: '7. Drawing Rules:  Each account is limited to 1 lucky draw daily.',
    desc16: '8. Prize Variants:',
    desc17: '9. Rewards Distribution Account:',
    desc18: '10. Rewards Distribution Time: Instant Credited.',
    desc19: '{n1} types of prizes.',
    desc20: 'Merry Jolly Lucky Draw',
    desc21: 'Counting Down',
    desc22: 'days',
    desc23: 'hours',
    desc24: 'mins',
    desc25: 'secs',
    desc26: 'Prizes you might won',
    desc27: 'DU',
    desc28: 'Open Now',
    desc29: 'Counting Down',
    desc30: 'Daily login to enjoy <span style="color:#C9FA5B"> {n1} times of drawing </span>, left <span style="color:#C9FA5B">{n2} </span> chances to draw today ',
    desc31: 'Refresh at 00:00 daily ',
    desc32: 'My Last Drawn',
    desc33: 'All Records',
    desc34: 'Platform Drawn Records',
    desc35: 'Total <span style="color:#C9FA5B">{n1}</span> users participate',
    desc36: 'ID',
    desc37: 'Prizes',
    desc38: 'Time',
    desc39: 'Draw Tomorrow',
    desc40: 'Not Available',
    desc41: 'Open Now',
    desc42: 'Congratulations! You’ve won a',
    desc43: 'Close',
    desc44: 'Rewards have been distributed to your {n1}',
    desc45: 'Click',
    desc46: 'Check Now',
    desc47: 'Records',
    desc48: 'Accumulated Drawn',
    desc49: 'Accumulated Mystery Rewards',
    desc50: 'Accumulated Rewards',
    desc51: 'Draw Time',
    desc52: 'Name',
    desc53: 'Details',
    desc54: 'Rewards Status',
    desc55: 'Distribution Time',
    desc56: 'To Account',
    desc57: 'Operation',
    desc58: 'Check Now',
    desc59: '{n1} times',
    desc60: '{n1} units',
    desc61: 'Distributed',
    desc62: 'Check',
    desc63: 'You are not eligible to join this campaign yet',
    desc64: 'Please <span style="color:#C9FA5B;cursor: pointer;">Subscribe a Bronze land and above</span> to draw',
    desc65: 'Prizes you might won',
    desc66: 'My Rewards',
    desc67: 'Rules',
    desc68: 'Platform Drawn',
    desc69: 'Platform drawn record',
    desc70: 'Coming Soon',
    desc71: 'Starting at',
    desc72: 'Campaign End Time',
    desc73: 'Ended',
    desc74: 'Coming Soon'

  },
  reward: {
    desc1: 'Join campaigns in the Reward Center',
    desc2: 'Hefty rewards await your collection',
    desc3: 'Limited rewards every season, grab them while stock lasts!',
    desc4: 'Campaigns',
    desc5: 'Complete Campaign to Earn Extra Rewards！',
    desc6: 'Ongoing',
    desc7: 'Starts Soon',
    desc8: 'Ended',
    desc9: 'Merry Jolly Campaign',
    desc10: 'Progress',
    desc11: 'Rewards',
    desc12: 'End Time',
    desc13: 'Number of Draw',
    desc14: 'Rewards Amount',
    desc15: 'Draw Now',
    desc16: 'Subscribe a Land',
    desc17: 'Campaign Rules',
    desc18: 'StarDUst Reward',
    desc19: 'Receive {n1} times of draw',
    desc20: 'StarDUst Reward Campaign',
    desc21: 'Deposit Amount',
    desc22: 'Rewards Amount',
    desc23: 'Deposit USDT and receive {n1}% DU rewards',
    desc24: 'Deposit USDT',
    desc25: 'Weekly Land Sales Leaderboard',
    desc26: 'New Land Sales',
    desc27: 'Land Sold Amount',
    desc28: 'Rewards Amount',
    desc29: 'Sold Out Leaderboard',
    desc30: 'Leaderboard',
    desc31: 'Manage',
    desc32: 'Weekly Board',
    desc33: 'Monthly Board',
    desc34: 'Subscribe Now',
    desc35: 'New Year Reassessment Bonanza',
    desc36: 'Total Add-On Amount',
    desc37: 'Rewards Amount',
    desc38: 'Land Sale Campaign',
    desc39: 'My Weekly Land Sales',
    desc40: 'Calculation Period:',
    desc41: 'Display Planets with land staking from Bronze and up only (excluding Stone).',
    desc42: 'Display weekly land staking within the campaign cycle only',
    desc43: 'Display weekly new land subscriptions only',
    desc44: 'Weekly Land Sales Leaderboard',
    desc45: 'Ranking',
    desc46: 'New Land Sales (Pieces)',
    desc47: 'Planet Rank',
    desc48: 'Planet Name',
    desc49: 'Planet Status',
    desc50: 'Planet Owner ',
    desc51: 'Display current week sales only',
    desc52: 'Ongoing',
    desc53: 'Start Time',
    desc54: '1. Campaign Name：Planetary Full Land Subscription Leaderboard Campaign',
    desc55: '2. Campaign Time：',
    desc56: '3. Eligible Users：All Users',
    desc57: '4. Campaign Description： During the campaign, any Planets that achieve full Land Subscription will be nominated in the Weekly Leaderboard.',
    desc58: '5. Reward Eligibility：Planetary Full Land Subscription Leaderboard only tracks down Planets with full Land Subscription from Bronze and up (excluding Stone) during the campaign.',
    desc59: '6. Rewards Amount：',
    desc60: '7. Rewards Type：',
    desc61: '8. Rewards Account：',
    desc62: '9. Rewards Distribution Time：Instantly Credited',
    desc63: 'Sold Out',
    desc64: 'Cumulative pledge quantity of planet land (blocks)',
    desc65: 'Available ',
    desc66: 'Not Open',
    desc67: 'Campaign Period:',
    desc68: 'Calculate to any planet type (excluding Stone Planet)',
    desc69: 'Campaign rewards will be distributed according to the Planet types:',
    desc70: 'Note: Rewards will be credited to the deposit account once the achieved the campaign requirement.',
    desc71: 'My Reassessment',
    desc72: 'My Rewards',
    desc73: 'Add-On Staking Campaign',
    desc74: 'New Year Reassessment Bonanza',
    desc75: 'Campaign Period:',
    desc76: 'Pool Rewards 1：Weekly',
    desc77: 'Countdown：',
    desc78: 'Pool Participants',
    desc79: 'Total Pool Rewards',
    desc80: 'Est. Received',
    desc81: 'Settlement Time:',
    desc82: 'Pool Rewards 2：Monthly',
    desc83: 'Weekly',
    desc84: 'Monthly',
    desc85: 'Campaign Rewards',
    desc86: 'During the campaign, the platform will funnel {n1}% of the Land Reassessment credit to the reward pool. {n2}% from the reward pool will be equally shared among the top 100 achievers with the highest reassessment value. {n3}% from the reward pool is distributed weekly, {n2}% from the reward pool is distributed monthly.',
    desc87: 'Type',
    desc88: 'Requirement',
    desc89: 'Rewards',
    desc90: 'Weekly Pool',
    desc91: 'Top-100 Users',
    desc92: 'Total Pool Rewards',
    desc93: 'Monthly Pool',
    desc94: 'All Reassess Users',
    desc95: 'Total Pool Rewards',
    desc96: 'Example：',
    desc97: 'The weekly accumulated reassessment amount is standing at 100,000 DU, 10% will be funneled into the reward pool, which is 10,000 DU',
    desc98: '30%= 3,000 DU will be equally distributed to the top 100 users',
    desc99: '70%= 7,000 DU will go to the monthly reward pool',
    desc100: 'By end of the month, all users who have reassessed their staking will share it equally; if the reward pool has accumulated 12,000 DU, and 1,000 users are eligible, each of them will share this 12,000 DU; 12000/1000 = 12 DU each',
    desc101: '{n1} users',
    desc102: 'Weekly Ranking',
    desc103: '1. Calculation Time：',
    desc104: '2. Display the top <span style="color:#C9FA5B;">100</span> highest reassessment users only .',
    desc105: '3. Land type is insignificant in land reassessment.',
    desc106: '4. Pool participants in the Weekly Leaderboard will equally share <span style="color:#C9FA5B;">{n1}%*{n2}%</span> from the reward pool',
    desc107: ' Monthly Ranking',
    desc108: '2. Display users with their reassessment initiated during the campaign period only',
    desc109: '4. Pool participants in the Monthly Leaderboard will equally share <span style="color:#C9FA5B;">{n1}%*{n2}%</span> from the reward pool',
    desc110: 'Add-On Amount',
    desc111: 'Account',
    desc112: 'Weekly Records',
    desc113: 'Monthly Records',
    desc114: 'Weekly total reassessment amount {n1}%*{n2}%。',
    desc115: 'Monthy total reassessment amount {n1}%*{n2}%。',
    desc116: 'Add-On Amount',
    desc117: 'Pool',
    desc118: 'Participants',
    desc119: 'Pool Rewards',
    desc120: 'Avg Rewards',
    desc121: ' Weekly',
    desc122: 'Settlement Time',
    desc123: 'Status',
    desc124: 'Completed',
    desc125: 'Pending ',
    desc126: 'Weekly',
    desc127: 'Monthly Pool Rewards',
    desc128: 'Pool',
    desc129: 'Quantity',
    desc130: 'Distribution Time',
    desc131: 'Operation',
    desc132: 'Check ',
    desc133: '2. Weekly rewards distribution once only per cycle',
    desc134: '2. Monthly rewards distribution once only per cycle ',
    desc135: 'Note:',
    desc136: 'Records',
    desc137: 'Weekly',
    desc138: 'Monthly',
    desc139: 'Records',
    desc140: 'Amount',
    desc141: 'Calculation Time',
    desc142: 'Ranking ',
    desc143: 'Weekly Reassessment',
    desc144: 'Monthly Reassessment',
    desc145: 'Weekly',
    desc146: 'Monthly',
    desc147: '1. Calculation is done once a week, and <span style="color:#C9FA5B;">{n1}%</span> of the total reassessment credit received is funneled into a reward pool: (Weekly Distribution) <span style="color:#C9FA5B;">{n2}% </span> from the reward pool is equally distributed to top <span style="color:#C9FA5B;">100</span> achievers; (Monthly Distribution) <span style="color:#C9FA5B;">{n3}% </span> from the reward pool is equally distributed to everyone who participates in the Land Reassessment campaign that particular month.',
    desc148: '2. Campaign Time：',
    desc149: '3. Eligible Users: All ',
    desc150: '4. Requirement: Limited to add-on staking during the campaign period only.',
    desc151: ' 5. Weekly Pool Rewards：',
    desc152: 'Week',
    desc153: 'Week {n1}',
    desc154: '6. Monthly Pool Rewards',
    desc155: '7. Rewards Type：',
    desc156: '8. Rewards Account：',
    desc157: '9. E.g.: This week, the total reassessment amount is 100,000 DU and 10% of the amount is funneled to the reward pool, with 30% from the reward pool = 3,000 DU will be equally distributed to the top 100 weekly achievers, whereas 70% from the reward pool = 7,000DU will be funneled to the monthly reward pool',
    desc158: 'By end of the month, all users who has reassessed their staking will share it equally; if the reward pool has accumulated 12,000 DU, and 1,000 users are eligible, each of them will share this 12,000 DU; 12000/1000=12 DU',
    desc159: 'Rewards Distribution Time',
    desc160: 'To',
    desc161: 'Planet land sold out list',
    desc162: 'New Year Reassessment Bonanza',
    desc163: '',
    desc164: 'Weekly Ranking',
    desc165: 'Monthly Ranking',
    desc166: 'Rules',
    desc167: 'Weekly Land Sales Leaderboard',
    desc168: 'The land has been sold out to receive {n1} DU rewards',
    desc169: 'Sold Out Leaderboard',
    desc170: 'Weekly Land Sale',
    desc171: 'Cosmic BOGO Event',
    desc172: 'Progress',
    desc173: 'Rewards',
    desc174: 'Free land',
    desc175: '{n1} Pieces',
    desc176: 'Received',
    desc177: 'Land Subscribe',
    desc178: 'Check Details',
    desc179: 'New users who subscribe to any 3 pieces of land (including Stone Land) during the campaign will receive 1 free piece of land.',
    desc180: 'During the event, new users get 1 free land of the lowest ranking and staking amount for every 3 lands subscribed.',
    desc181: '2. Rules',
    desc182: '2.1 Applicable to new accounts with no previous land subscriptions.',
    desc183: 'No land type restrictions; all land types qualify for this campaign.',
    desc184: 'Users can receive more than 1 free piece of land. For instance, users subscribing to 6 lands will receive a total of 2 free pieces of land.',
    desc185: '2.4 Land subscriptions must be made with a Deposit Account (DU/DU+HPT); subscriptions with a  Reward Account will not qualify.',
    desc186: '3. Campaign Period',
    desc187: '4. Campaign Rewards ',
    desc188: 'Based on the subscription sequence, for every 3 pieces of land subscribed, the piece of land with the lowest ranking will be given as the campaign reward with the lowest staking amount of that particular piece of land.',
    desc189: '5. Rewards Distribution',
    desc190: 'Instantly Credited, check on the [My Land] page.',
    desc191: '6. Examples:',
    desc192: '1. If User A subscribes to 3 pieces of Stone Land and, the user will receive 1 piece 10 DU worth of Stone Land.',
    desc193: '2. If User B subscribes to 1 piece of Stone Land （300DU）, 1 piece of Bronze Land, and 1 piece of Iron Land, the user will receive 1 piece 10 DU worth of Stone Land.',
    desc194: '3. If User C subscribes to 2 pieces of Bronze Land and 6 pieces of Iron Land, the user will receive 1 piece 101 DU worth of Bronze Land and 1 piece 301 DU worth of Iron Land.',
    desc195: 'Intergalactic Land Sale',
    desc196: 'Free Planet',
    desc197: 'Planet Subscribe',
    desc198: 'New users who purchase 2 planets during the event period will receive 1 free planet.',
    desc199: '2.1 This offer is limited to accounts that have not subscribed to any planets previously. Planets subscribed to before this campaign will not be considered for this promotion.',
    desc200: '2.2 Users must subscribe to at least 2 planets (including Stone Planet) during the campaign.',
    desc201: '2.3 Users can receive more than 1 free planet. For example, users who subscribe to 4 planets can receive a total of 2 free planets.',
    desc202: 'Following the subscription sequence, for every 2 planets purchased, the lower-ranking planet will be given as the campaign reward.',
    desc203: 'Instantly Credited, check on the [Manage] page.',
    desc204: '1. If User A had subscribed to 3 Bronze Planets before this campaign, this user does not qualify for the campaign rewards.',
    desc205: '2. If User B subscribes to 3 Stone Planets during this campaign, this user is qualified for the campaign rewards.',
    desc206: '3. If User C subscribes to 1 Iron Planet, 1 Electric Planet, and 1 Industrial Planet during the campaign, this user will receive 1 free Iron Planet as a reward since the ranking of the Iron Planet is lower than the Electric Planet.',
    desc207: '4. If User D initially subscribes to 3 Iron Planets and 3 Electric Planets during the campaign, this user will receive 2 free Iron Planets and 1 Electric Planet.',
    desc208: '',
    desc209: 'New users who purchase 2 planets during the event period will receive 1 free planet.',
    desc210: 'Intergalactic Land Sale: Buy 3 Free 1',
    desc211: 'New users who purchase any 3 lands (including Stone Land) during the event period will receive 1 free land.',
    desc212: '1. Description',
    desc213: '2.4 The complimentary planet referral rewards will be given to the last person who is referred during the campaign. For example: if User A has subscribed to 2 Iron Planets, the first and the second referrals are different. The free planet will be referred by the second referral. If there’s no referral for the 2 planets, the free planet will be without the referral. ',
    desc214: 'Subscribe to any 3 lands, receive 1 land for free',
    desc215: 'Subscribe to any 2 planets, receive 1 planet for free',
    desc216: 'Gift Quantity',
    desc217: 'Purchased Quantity',
    desc218: '{n1} Pieces',
    desc219: '',
    desc220: '2.5The complimentary planet referral rewards will be given to the last person who is referred during the campaign. For example: if User A has subscribed to 2 Iron Planets, the first and the second referrals are different. The free planet will be referred by the second referral. If there’s no referral for the 2 planets, the free planet will be without the referral.',
    desc221:'From',
    desc222:'Subscription',
    desc223:'Rewards',
    desc224:'Rewards',
  },
  daot: {
    desc1: 'DAOT',
    desc2: 'DAOT Subscription',
    desc3: 'Open the door to freedom of wealth',
    desc4: 'Countdown to the end of subscription',
    desc5: 'Days',
    desc6: 'Hours',
    desc7: 'Minutes',
    desc8: 'Seconds',
    desc9: 'Token price',
    desc10: 'Total quantity of DAOT sold',
    desc11: 'Personal subscription quantity',
    desc12: 'Sale progress',
    desc13: 'Subscription method: first come first served, while supplies last',
    desc14: 'subscription',
    desc15: 'No upper limit',
    desc16: 'Subscribed quantity',
    desc17: 'Subscription address',
    desc18: 'Amount of RC that can be subscribed',
    desc19: 'Amount of DU that can be subscribed',
    desc20: 'Currency issuance',
    desc21: '2-year linear release',
    desc22: 'edit',
    desc23: 'Token name',
    desc24: 'Total amount of issuance',
    desc25: 'Token type',
    desc26: 'Contract address',
    desc27: 'FAQ',
    desc28: 'Payment account',
    desc29: 'Bonus Account',
    desc30: 'Investment Account',
    desc31: 'Payment currency',
    desc32: 'Pay quantity',
    desc33: 'Please enter the payment quantity',
    desc34: 'Solana V2 wallet address',
    desc35: 'Please enter wallet address',
    desc36: 'price',
    desc37: 'Estimated number of DAOTs to be obtained',
    desc38: 'Account balance:',
    desc39: 'Subscription successful',
    desc40: 'Edit address',
    desc41: 'Subscription address',
    desc42: 'Please enter your subscription address',
    desc43: 'Modification successful',
    desc44: 'Or',
    desc45: 'DAOT is the platform currency of the daoversal platform and will be listed on mainstream exchanges in the future.',
    desc46: 'Dear Esteemed Member:',
    desc47: 'We extend our heartfelt gratitude for your invaluable contributions to our community. Your continued support entitles you to an exclusive opportunity to subscribe to the pre-launch of the DAOT token using DU/RC.',
    desc48: 'By selecting "YES," you gain the privilege to allocate rewards from March 1 to March 31st towards DAOT subscriptions, Land Purchase, and Planet Purchase. Please note that this offer is non-reversible and will temporarily freeze withdrawals until the presale allocation has been completed. ',
    desc49: 'The DAOT token is scheduled for launch on April 15th at 11 am GST. As an early access user, you are granted the right to acquire tokens using DU/RC from March 1st to March 31st.',
    desc50: 'During the pre-launch phase, tokens are priced at 2.00$ per token, with a total supply of 100,000,000. Following the launch, the listing price will increase to 3.00$ per token when trading begins.',
    desc51: 'Thank you for your unwavering dedication. Let us embark together on this journey towards a decentralized future.',
    desc52: 'Do not subscribe',
    desc53: 'Subscribe to DAOT',
    desc54: '1. How long is the subscription time?',
    desc56: 'The subscription time is from 2024-03-04 20:00:00-2024-04-03 19:59:59 (GST). If DAOT is sold out in advance, the subscription will end.',
    desc57: '2. Subscription objects',
    desc58: 'All users can participate in subscription. Before subscribing, you need to click "I participate in subscribing DAOT" in the announcement pop-up box to participate in subscribing, and the withdrawal function of users who participate in subscribing will be locked for 30 days. Users who click "Do not participate in subscribing DAOT" will not be able to participate in subscribing and the withdrawal function will be available. Normal use.',
    desc59: '3. What currencies and account subscriptions are supported?',
    desc60: 'DU of investment accounts, DU and RC of bonus accounts are supported to participate in the subscription. When subscribing, you need to select the currency and account to participate in the subscription. The assets in the trading account need to be manually transferred to the bonus account to participate in the subscription.',
    desc61: '4. Can the principal be redeemed if the subscription is successful?',
    desc62: 'Once the subscription is successful, redemption of principal is not supported',
    desc63: '5. Is there a subscription quantity limit for each account?',
    desc64: 'There is no maximum subscription quantity limit for each account, first come first served, while supplies last',
    desc65: '6. When will DAOT be released after the subscription is completed?',
    desc66: 'After the subscription is completed, an announcement will be issued to notify DAOT of the redemption matters.',
    desc67: '7. What is the Solana V2 wallet address used for?',
    desc68: 'The wallet is used to receive DAOT. The address can be edited before the event ends.',
    desc69: '*The current account has chosen not to participate in subscription',
    desc70: 'Not started',
    desc71: 'Has ended',
    desc72: 'Sold out',
    desc73: "The Daoversal ecosystem is proud to launch DAOT, the interplanetary token that aims to disrupt the landscape of the Daoversal platform. DAOT acts as the foundational bedrock by offering a governance mechanism to preserve the entire ecosystem's sovereignty. It also focuses on propelling immersive in-world economies by promoting a planetary barter system. DAOT's smart contract represents a more dynamic, inclusive, and decentralized mechanism designed for the metaverse ecosystem's evolution.",
  },
  vote:{
    desc1: 'Daoversal Governance Voting',
     desc2:'Not started',
     desc3:'in progress',
     desc4:'Ended',
     desc5:'Increase DAOT token liquidity and community participation',
     desc6:'Activity time:',
     desc7: 'The prosperity of the DAOT token is critical to the sustainable growth and success of our ecosystem. To achieve this, we need to actively encourage community involvement and participation. In this proposal, we outline several measures aimed at increasing DAOT token liquidity and incentivizing community participation, specifically through the DAOT presale distribution. ',
     desc8:'Number of votes',
     desc9:'distance end',
     desc10:'day',
     desc11:'hour',
     desc12:'fen',
     desc13:'second',
     desc14:'Proposal content',
     desc15:'Number of votes',
     desc16:'voting rate',
     desc17:'distance start',
     desc18:'key point',
     desc19:'1. Simplify the work of supporting DAOT tokens',
     desc20:'- In order to simplify and focus on supporting the DAOT token, we recommend canceling all existing withdrawal requests. ',
     desc21:'- DU from these canceled requests will be redirected into the DAOT presale allocation and distributed to respective users to enhance liquidity and support the growth of the token. ',
     desc22:'2. Transfer of Platform Rewards to Presale Allocation:',
     desc23:' - All rewards generated on the platform will be automatically transferred into the DAOT presale allocation and assigned to their respective user.',
     desc24:' - All RC/DU balances currently on the platform will be automatically transferred into the DAOT presale allocation and assigned to their respective user.',
     desc241:' - This measure ensures that the rewards generated contribute to the liquidity and strength of the DAOT token.',
     desc25:'3. Additional DAOT allocation:',
     desc26:'- To further strengthen the pre-sale allocation and ensure the platform can support generative rewards before April 15th, we propose to add an additional 500,000 DAOT tokens. ',
     desc27:'- This additional allocation will enhance liquidity and provide sufficient resources to support ongoing platform activities and rewards. ',
     desc28:'voting message',
     desc29:'voting status',
     desc30:'Voting start time',
     desc31:'Poll end time',
     desc32:'voting form',
     desc33:'Participant',
     desc34:'voting form',
     desc35:'single choice',
     desc36:'1 valid piece of land can cast 1 vote',
     desc37:'all users',
     desc38:'Note: One piece of land in effect can cast one vote, and there are several pieces of land in effect that can cast several votes. It cannot be changed after a successful vote. ',
     desc39:'Vote your vote',
     desc40:'voting record',
     desc41:'vote',
     desc42:'<span style="color:#C9FA5B">{n1}</span> votes remaining',
     desc43:'Already participated in voting',
     desc44:'Vote successful',
     desc45:'name',
     desc46:'voting date',
     desc47:'voting content',
     desc48:'status',
     desc49:'Daoversal Governance Voting',
     desc50:'voted',
     desc51:'Itroduction',
     desc52:'Introduction'
  },
  transferAccount:{
    desc0:'Hyper User Migration',
    desc1:'E-mail',
    desc2:'Input the E-mail address',
    desc3:'Username',
    desc4:'Input Username',
    desc5:'Password',
    desc6:'Input Password',
    desc7:'Confirm Password',
    desc8:'Input Password Again',
    desc9:'E-mail verification code',
    desc10:'Enter the email verification code',
    desc11:'Email address cannot be empty',
    desc12:'Username cannot be empty',
    desc13:'Password can not be empty',
    desc14:'Passwords are inconsistent',
    desc15:'Email verification code cannot be empty',
    desc16:'E-mail format is incorrect',
    desc17:'Migrate Account',
    desc18:'Understood',
    desc19:'We would like to inform you that the one-click login feature on HyperCommunity will be phased out on May 4th,2024 at 23:59 GST.',
    desc20:'HyperCommunity Migration',
    desc21:'Only Hyper users can do account migration',
    desc22:'Account migration successful',
    desc23:'Account Migration Notice',
    desc24:'To ensure uninterrupted access and security of your assets, user must initiate the account migration process to DAOVERSAL.',
    desc25:'Your prompt action is appreciated as failure to migrate your account within the stipulated timeframe may result in potential asset loss. We appreciate your cooperation and understanding.'
  },
  lockrelease:{
    desc1: "Token Name",
    desc2: "Total Supply",
    desc3: "Token type",
    desc4: "Contract Address",
    desc5: "Detail",
    desc6: "Subscribed Quantity",
    desc7: "Unreleased Amount",
    desc8: "Claimed",
    desc9: "Await Claim",
    desc10: "Claim",
    desc11: "The Daoversal ecosystem is proud to launch DAOT, the interplanetary token that aims to disrupt the landscape of the Daoversal platform. DAOT acts as the foundational bedrock by offering a governance mechanism to preserve the entire ecosystem's sovereignty. It also focuses on propelling immersive in-world economies by promoting a planetary barter system. DAOT's smart contract represents a more dynamic, inclusive, and decentralized mechanism designed for the metaverse ecosystem's evolution.",
    desc12: "Release Record",
    desc13: "No",
    desc14: "Release Time",
    desc15: "Release Amount",
    desc16: "Status",
    desc17: "Await Claim",
    desc18: "Claimed",
    desc19: "Claim Success",
    desc20: "DAOT",
    desc21: "DAOT SUBSCRIPTION",
  }
};
export default en_US;

import { createRouter, createWebHistory } from "vue-router";
import i18n from "../public/lang/i18n";
import store from "../store";
import bus from "@/utils/bus";
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        title: "home.desc",
        keepAlive: false,
      },
      component: () => import("@/views/newhome/index.vue"),
    },
    {
      path: "/reg",
      name: "reg",
      meta: {
        title: "home.desc",
        keepAlive: false,
      },
      component: () => import("@/views/reg.vue"),
    },
    // {
    //   path: "/point",
    //   name: 'point',
    //   meta: {
    //     title: "home.desc40",
    //     keepAlive: false
    //   },
    //   component: () => import("@/views/point/index.vue")
    // },
    {
      path: "/planet",
      name: "planet",
      meta: {
        title: "home.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/planet/index.vue"),
    },
    {
      path: "/planet/info",
      name: "planetinfo",
      meta: {
        title: "planet.desc13",
        keepAlive: false,
      },
      component: () => import("@/views/planet/info.vue"),
    },
    {
      path: "/planet/record",
      name: "planetrecord",
      meta: {
        title: "planet.desc53",
        keepAlive: false,
      },
      component: () => import("@/views/planet/record.vue"),
    },
    {
      path: "/mining",
      name: "mining",
      meta: {
        title: "home.desc4",
        keepAlive: false,
      },
      component: () => import("@/views/mining/index.vue"),
    },
    {
      path: "/asset",
      name: "asset",
      meta: {
        title: "asset.desc",
        keepAlive: false,
      },
      component: () => import("@/views/asset/index.vue"),
    },
    {
      path: "/third/transfer",
      name: "thirdtransfer",
      meta: {
        title: "asset.desc114",
        keepAlive: false,
      },
      component: () => import("@/views/asset/thirdtransfer.vue"),
    },
    {
      path: "/income-record",
      name: "incomeRecord",
      meta: {
        title: "asset.desc",
        keepAlive: false,
      },
      component: () => import("@/views/asset/asset-bag/income-record.vue"),
    },
    {
      path: "/backfill-record",
      name: "backfillRecord",
      meta: {
        title: "asset.desc",
        keepAlive: false,
      },
      component: () => import("@/views/asset/asset-bag/backfill-record.vue"),
    },
    {
      path: "/asset-detail",
      name: "assetDetail",
      meta: {
        title: "home.desc2",
        keepAlive: false,
      },
      component: () => import("@/views/asset/detail.vue"),
    },
    {
      path: "/asset/transfer",
      name: "assettransfer",
      meta: {
        title: "transfer.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/asset/transfer.vue"),
    },
    {
      path: "/dsjl-detail",
      name: "dsjlDetail",
      meta: {
        title: "myincome.desc57",
        keepAlive: false,
      },
      component: () => import("@/views/asset/dsjl-detail.vue"),
    },
    {
      path: "/tdss-detail",
      name: "tdssDetail",
      meta: {
        title: "myincome.desc57",
        keepAlive: false,
      },
      component: () => import("@/views/asset/tdss-detail.vue"),
    },
    {
      path: "/record",
      name: "record",
      meta: {
        title: "asset.desc4",
        keepAlive: false,
      },
      component: () => import("@/views/asset/record.vue"),
    },
    {
      path: "/record/detail",
      name: "recorddetail",
      meta: {
        title: "home.desc2",
        keepAlive: false,
      },
      component: () => import("@/views/asset/iconinfo.vue"),
    },
    {
      path: "/third/record",
      name: "thirdrecord",
      meta: {
        title: "asset.desc124",
        keepAlive: false,
      },
      component: () => import("@/views/asset/thirdrecord.vue"),
    },
    {
      path: "/third/recordinfo",
      name: "thirdrecordinfo",
      meta: {
        title: "asset.desc135",
        keepAlive: false,
      },
      component: () => import("@/views/asset/thirdrecordinfo.vue"),
    },
    {
      path: "/record-detail",
      name: "recordDetail",
      meta: {
        title: "asset.desc4",
        keepAlive: false,
      },
      component: () => import("@/views/asset/record-detail.vue"),
    },
    {
      path: "/pool",
      name: "pool",
      meta: {
        title: "home.desc5",
        keepAlive: false,
      },
      component: () => import("@/views/pool/index.vue"),
    },
    {
      path: "/poolInfo",
      name: "poolinfo",
      meta: {
        title: "home.desc5",
        keepAlive: false,
      },
      component: () => import("@/views/pool/info.vue"),
    },
    {
      path: "/recharge",
      name: "recharge",
      meta: {
        title: "asset.desc2",
        keepAlive: false,
      },
      component: () => import("@/views/asset/recharge.vue"),
    },
    {
      path: "/withdraw",
      name: "withdraw",
      meta: {
        title: "asset.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/asset/withdraw.vue"),
    },
    {
      path: "/rechange/record",
      name: "rechangerecord",
      meta: {
        title: "asset.desc8",
        keepAlive: false,
      },
      component: () => import("@/views/asset/h5record.vue"),
    },
    {
      path: "/transfer/record",
      name: "transferrecord",
      meta: {
        title: "transfer.desc10",
        keepAlive: false,
      },
      component: () => import("@/views/asset/h5transferrecord.vue"),
    },
    {
      path: "/record/info",
      name: "recordinfo",
      meta: {
        title: "asset.desc83",
        keepAlive: false,
      },
      component: () => import("@/views/asset/recordinfo.vue"),
    },
    {
      path: "/transferrecord/info",
      name: "transferrecordinfo",
      meta: {
        title: "transfer.desc21",
        keepAlive: false,
      },
      component: () => import("@/views/asset/transfertrecordinfo.vue"),
    },
    {
      path: "/withdraw/record",
      name: "withdrawrecord",
      meta: {
        title: "withdraw.desc31",
        keepAlive: false,
      },
      component: () => import("@/views/asset/withdraw-record.vue"),
    },
    {
      path: "/withdraw/record/detail",
      name: "withdrawrecorddetail",
      meta: {
        title: "withdraw.desc32",
        keepAlive: false,
      },
      component: () => import("@/views/asset/withdraw-record-detail.vue"),
    },
    {
      path: "/swap",
      name: "swap",
      meta: {
        title: "asset.desc3",
        keepAlive: false,
      },
      component: () => import("@/views/swap/index.vue"),
    },
    {
      path: "/invite",
      name: "invite",
      meta: {
        title: "asset.desc5",
        keepAlive: false,
      },
      component: () => import("@/views/invite/index.vue"),
    },
    {
      path: "/invite/info/:id",
      name: "inviteinfo",
      meta: {
        title: "invite.desc15",
        keepAlive: false,
      },
      component: () => import("@/views/invite/list.vue"),
    },
    {
      path: "/node",
      name: "node",
      meta: {
        title: "new.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/node/index.vue"),
    },
    {
      path: "/land",
      name: "land",
      meta: {
        title: "new.desc2",
        keepAlive: false,
      },
      component: () => import("@/views/land/index.vue"),
    },
    {
      path: "/manage",
      name: "manage",
      meta: {
        title: "new.desc3",
        keepAlive: false,
      },
      component: () => import("@/views/manage/index.vue"),
    },
    {
      path: "/manage/modify",
      name: "managemodify",
      meta: {
        title: "new.desc3",
        keepAlive: false,
      },
      component: () => import("@/views/manage/modify.vue"),
    },
    {
      path: "/manage/transfer",
      name: "managetransfer",
      meta: {
        title: "manage.desc58",
        keepAlive: false,
      },
      component: () => import("@/views/manage/transfer.vue"),
    },
    {
      path: "/manage/record",
      name: "managerecord",
      meta: {
        title: "manage.desc87",
        keepAlive: false,
      },
      component: () => import("@/views/manage/record.vue"),
    },
    {
      path: "/quotes",
      name: "quotes",
      meta: {
        title: "new.desc5",
        keepAlive: false,
      },
      component: () => import("@/views/quotes/index.vue"),
    },
    {
      path: "/quotes/record",
      name: "quotesrecord",
      meta: {
        title: "quotes.desc30",
        keepAlive: false,
      },
      component: () => import("@/views/quotes/record.vue"),
    },
    {
      path: "/quotes/recordinfo",
      name: "quotesrecordinfo",
      meta: {
        title: "quotes.desc31",
        keepAlive: false,
      },
      component: () => import("@/views/quotes/info.vue"),
    },
    {
      path: "/login",
      name: "login",
      meta: {
        title: "sign.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/login/index.vue"),
    },
    {
      path: "/error",
      name: "error",
      meta: {
        title: "sign.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/login/error.vue"),
    },
    {
      path: "/register",
      name: "register",
      meta: {
        title: "sign.desc2",
        keepAlive: false,
      },
      component: () => import("@/views/login/register.vue"),
    },
    {
      path: "/forgot",
      name: "forgot",
      meta: {
        title: "sign.desc54",
        keepAlive: false,
      },
      component: () => import("@/views/login/forgot.vue"),
    },
    {
      path: "/forgotname",
      name: "forgotname",
      meta: {
        title: "sign.desc58",
        keepAlive: false,
      },
      component: () => import("@/views/login/forgotname.vue"),
    },
    {
      path: "/bindname",
      name: "bindname",
      meta: {
        title: "sign.desc63",
        keepAlive: false,
      },
      component: () => import("@/views/login/bindname.vue"),
    },
    {
      path: "/bindhv",
      name: "bindhv",
      meta: {
        title: "sign.desc63",
        keepAlive: false,
      },
      component: () => import("@/views/login/bindhv.vue"),
    },
    {
      path: "/binduser",
      name: "binduser",
      meta: {
        title: "sign.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/login/binduser.vue"),
    },
    {
      path: "/user",
      name: "user",
      meta: {
        title: "footer.desc25",
        keepAlive: false,
      },
      component: () => import("@/views/user/index.vue"),
    },
    {
      path: "/user/info",
      name: "userinfo",
      meta: {
        title: "",
        keepAlive: false,
      },
      component: () => import("@/views/user/info.vue"),
    },
    {
      path: "/article",
      name: "article",
      meta: {
        title: "",
        keepAlive: false,
      },
      component: () => import("@/views/user/article.vue"),
    },
    {
      path: "/market",
      name: "market",
      meta: {
        title: "home.desc63",
        keepAlive: false,
      },
      component: () => import("@/views/market/index.vue"),
    },
    {
      path: "/market/rules",
      name: "marketrules",
      meta: {
        title: "market.desc177",
        keepAlive: false,
      },
      component: () => import("@/views/market/rate.vue"),
    },
    {
      path: "/brand",
      name: "brand",
      meta: {
        title: "brand.desc83",
        keepAlive: false,
      },
      component: () => import("@/views/brand/index.vue"),
    },
    {
      path: "/brand/component",
      name: "brandcomponent",
      meta: {
        title: "brand.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/brand/component.vue"),
    },
    {
      path: "/brand/resource",
      name: "brandresource",
      meta: {
        title: "brand.desc2",
        keepAlive: false,
      },
      component: () => import("@/views/brand/resource/index.vue"),
    },
    {
      path: "/blind",
      name: "blind",
      meta: {
        title: "blind.desc4",
        keepAlive: false,
      },
      component: () => import("@/views/blind/index.vue"),
    },
    {
      path: "/reward",
      name: "reward",
      meta: {
        title: "blind.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/reward/index.vue"),
    },
    {
      path: "/reward/pledge",
      name: "rewardpledge",
      meta: {
        title: "reward.desc38",
        keepAlive: false,
      },
      component: () => import("@/views/reward/pledge.vue"),
    },
    {
      path: "/reward/reinvest",
      name: "rewardreinvest",
      meta: {
        title: "reward.desc35",
        keepAlive: false,
      },
      component: () => import("@/views/reward/reinvest.vue"),
    },
    {
      path: "/reward/carve",
      name: "rewardcarve",
      meta: {
        title: "",
        keepAlive: false,
      },
      component: () => import("@/views/reward/carve.vue"),
    },
    {
      path: "/reward/speed",
      name: "rewardspeed",
      meta: {
        title: "market.desc203",
        keepAlive: false,
      },
      component: () => import("@/views/reward/speed.vue"),
    },
    {
      path: "/reward/planet",
      name: "rewardplanet",
      meta: {
        title: "reward.desc171",
        keepAlive: false,
      },
      component: () => import("@/views/reward/planet.vue"),
    },
    {
      path: "/reward/land",
      name: "rewardland",
      meta: {
        title: "reward.desc38",
        keepAlive: false,
      },
      component: () => import("@/views/reward/land.vue"),
    },
    {
      path: "/reward/record",
      name: "rewardrecord",
      meta: {
        title: "reward.desc139",
        keepAlive: false,
      },
      component: () => import("@/views/reward/record.vue"),
    },
    {
      path: "/reward/prize",
      name: "rewardprize",
      meta: {
        title: "reward.desc136",
        keepAlive: false,
      },
      component: () => import("@/views/reward/prize.vue"),
    },
    {
      path: "/reward/rules",
      name: "rewardrules",
      meta: {
        title: "reward.desc17",
        keepAlive: false,
      },
      component: () => import("@/views/reward/rules.vue"),
    },
    {
      path: "/blind/record",
      name: "blindrecord",
      meta: {
        title: "blind.desc47",
        keepAlive: false,
      },
      component: () => import("@/views/blind/record.vue"),
    },
    {
      path: "/daot",
      name: "daot",
      meta: {
        title: "daot.desc1",
        keepAlive: false,
      },
      component: () => import("@/views/daot/index.vue"),
    },
    {
      path: "/lockRelease",
      name: "lockRelease",
      meta: {
        title: "daotsubs.desc50",
        keepAlive: false,
      },
      component: () => import("@/views/lockrelease/index.vue"),
    },
    {
      path: "/vote",
      name: "vote",
      meta: {
        title: "vote.desc41",
        keepAlive: false,
      },
      component: () => import("@/views/vote/index.vue"),
    },
    {
      //路由重定向
      path: "/:pathMatch(.*)*",
      redirect: "/home",
    },
  ],
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});
router.beforeEach((to, from, next) => {
  document.title = `${i18n.global.t(to.meta.title)}`;
  bus.emit("handlerShow", false);
  // bus.emit('handlerHiden', true)
  if (localStorage.getItem('ismobile') == 0 && to.path == '/home' && localStorage.getItem('showActivity') == null || localStorage.getItem('ismobile') == 0 && to.path == '/asset' && localStorage.getItem('showActivity') == null) {
    localStorage.setItem('showActivity', 0)
  }
  if (localStorage.getItem('ismobile') == 1 && localStorage.getItem('showActivity') == null) {
    localStorage.setItem('showActivity', 0)
  }
  // if (to.path == '/blind' || to.path == '/blind/record' || to.path == '/reward/pledge' || to.path=='/reward') {
  //   bus.emit('handlerHiden', false)
  // }
  if (localStorage.token) {
    store.dispatch("openIntegralUseToast");
  }
  next();
});
export default router;

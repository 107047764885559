import { createApp } from "vue";
import App from "../App";
const app = createApp(App);
import axios from "axios";
import router from "../router";
import store from "../store";
import common from "../utils/common";
import { ElMessage } from "element-plus";
import i18n from "../public/lang/i18n";
import { Toast } from "vant";
app.use(ElMessage);

axios.defaults.timeout = 15000; //请求超时5秒
axios.defaults.baseURL = process.env.VUE_APP_URL; //请求base url
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"; //设置post请求是的header信息

//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    let token =
        localStorage.getItem("token") == null
          ? ""
          : localStorage.getItem("token"),
      lang = localStorage.getItem("language");
    let timestamp = new Date().getTime();
    
    if (config.params != undefined) {
      if (config.params.isNotice != undefined) {
        config.baseURL = process.env.VUE_APP_NOTECE_URL;
      }
    }
    if (config.isUpload) {
      var obj = new Object();
    } else {
      var obj = Object.assign({}, config.data);
    }

    obj.timestamp = timestamp;

    if (process.env.VUE_APP_URL != "https://star-api.daoversal.com/api/") {
      obj.apiKey = "test";
    } else {
      obj.apiKey = "qXbvtfkp";
    }

    // obj.apiKey = "mc-api-key";
    // 去掉签名对象里面的空值，未定义字段
    for (var key in obj) {
      if (obj[key] === "" || undefined === obj[key] || null === obj[key]) {
        delete obj[key];
      }
    }
    obj.sign = common.sign(obj);
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      // 判断是否是upload模式，如果是则需要改为form-data格式
      if (config.isUpload) {
        let formData = new FormData(); //new一个formData事件
        formData.append("file", config.data.file);
        formData.append("sign", obj.sign);
        formData.append("timestamp", obj.timestamp);
        formData.append("apiKey", obj.apiKey);
        config.data = formData;
        config.headers = {
          "Content-Type": "multipart/form-data",
          system: "android",
          token,
          lang,
        };
      } else {
        config.data = obj;
        config.data = JSON.stringify(config.data);
        config.headers = {
          "Content-Type": "application/json",
          system: "android",
          token,
          lang,
        };
      }
      // _bus.$emit('showloading')
    } else {
      config.data = obj;
      config.headers = {
        "Content-Type": "application/json",
        system: "android",
        lang,
      };
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      store.commit("SET_TOKEN", "");
      store.commit("SET_USERDATA", {});
      store.commit("SET_LOGIN", false);
      localStorage.removeItem("vuex");
      localStorage.removeItem("token");
      localStorage.removeItem("address");
      localStorage.removeItem("walletconnect");
      let msg = ElMessage({
        message: `${i18n.global.t("home.desc39")}`,
        type: "error",
      });
      router.push("/login");
      setTimeout(() => {
        msg.close();
        //location.reload();
      }, 1000);
      return;
    }

    return response;
  },
  (err) => {
    // _bus.$emit('closeLoading')
    if (err.code === "ECONNABORTED" && err.message.indexOf("timeout") !== -1) {
      ElMessage({
        message: `${i18n.global.t("login.desc12")}`,
        timeout: 1500,
      });
    }
    return Promise.reject(err);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, config = { isUpload: false }) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
0;

// export var bus = _bus
